export const en = {
    dashboard: 'Dashboard',
    items_selected: 'items selected',
    menu: 'Menu',
    back: 'Back',
    edit: 'Edit',
    create: 'Create',
    create_and_view: 'Create and view',
    create_and_edit: 'Create and edit',
    filters: 'Filters',
    clear: 'Clear',
    update: 'Update',
    close: 'Close',
    apply: 'Apply',
    proceed: 'Proceed',
    delete: 'Delete',
    actions: 'Actions',
    cancel: 'Cancel',
    fill: 'Fill',
    description: 'Description',
    platform_name: 'Platform Name',
    sub_platform_name: 'Sub Platform Name',
    parent_platform_name: 'Parent Platform',
    platform: 'Platform',
    platforms: 'Platforms',
    create_platform: 'Create Platform',
    url: 'URL',
    summary: 'Summary',
    clearing_system: 'Clearing System',
    clearing_systems: 'Clearing Systems',
    clearing_system_ci: 'Crowd Investing (CI)',
    clearing_system_cf: 'Crowd Funding (CF)',
    clearing_system_emission: 'Emission (EMISSION)',
    terms: 'Terms and conditions',
    privacy_policy: 'Privacy Policy',
    platform_attachments: 'Platform Attachments',
    select_all: 'Select all',
    id: 'ID',
    name: 'Name',
    type: 'Type',
    file: 'File',
    choose_file: 'Choose file',
    choose_image: 'Choose image',
    reset: 'Reset',
    information_letter: 'Information letter',
    platform_webhooks: 'Platform Webhooks',
    platform_webhook: 'Platform Webhook',
    event: 'Event',
    secret: 'Secret',
    platform_policies: 'Platform Policies',
    platform_policy: 'Platform Policy',
    country: 'Country',
    countries: 'Countries',
    language: 'Language',
    content: 'Content',
    platform_email_templates: 'Platform Email Templates',
    subject: 'Subject',
    placeholders: 'Placeholders',
    insert: 'Insert',
    platform_disclaimer: 'Platform Disclaimer',
    platform_disclaimers: 'Platform Disclaimers',
    countries_applied: 'Applied in',
    countries_not_applied: 'Not applied in',
    platform_details: 'Platform details',
    platform_logo: 'Logo',
    links: 'Links',
    privacy_url: 'Privacy Policy',
    email_settings: 'Email Settings',
    admin_email: 'Admin Email',
    mail_template: 'Email Template',
    order_status_for_total_funding_sum: 'Total Funding Sum',
    pending: 'Pending',
    paid: 'Paid',
    accepted: 'Accepted',
    smtp_settings: 'SMTP Settings',
    mail_host: 'SMTP Server',
    mail_port: 'SMTP Port',
    mail_encryption: 'Encryption',
    none: 'None',
    mail_from_address: 'Sender Email',
    mail_form_name: 'Sender Name',
    mail_username: 'SMTP Username',
    mail_password: 'SMTP Password',
    assets: 'Assets',
    asset: 'Asset',
    asset_name: 'Assets Name',
    currency: 'Currency',
    create_asset: 'Create Asset',
    asset_title: 'Asset Title',
    asset_details: 'Asset details',
    asset_category: 'Asset category',
    asset_location: 'Asset Location',
    city: 'City',
    state: 'State',
    asset_funding_limits: 'Funding limits',
    asset_funding_min: 'Funding minimum',
    asset_funding_max: 'Funding maximum',
    asset_investment_min: 'Investment minimum',
    asset_investment_max: 'Investment maximum',
    asset_step_level: 'Investment step level',
    asset_base_funding_count: 'Base Investments Count',
    asset_base_funding_total: 'Base Investment Amount',
    base_funding_total: 'Investment Amount',
    manual_funding_totals: 'Manual Funding Totals',
    asset_manual_funding_totals: 'Manual Funding Totals',
    automatic_payments: 'Automatic Payments',
    asset_deactivate_payment_account: 'Deactivate automatic payments',
    asset_documents: 'Asset Documents',
    asset_documents_contract_data: 'Contract Details',
    asset_documents_0: 'Contract Data',
    asset_documents_1: 'Reporting',
    asset_documents_2: 'Consumer Information',
    company_details: 'Company Details',
    company_name: 'Company Name',
    company_logo: 'Company Logo',
    address: 'Address',
    postal_code: 'Postal Code',
    phone_number: 'Phone Number',
    email: 'Email',
    company_website: 'Company Website',
    iban: 'Company IBAN',
    bic: 'Company BIC',
    contract: 'Contract',
    contracts: 'Contracts',
    contract_details: 'Contract Details',
    contract_range: 'Contract Range',
    interest_rate: 'Interest Rate',
    signatures: 'Signatures',
    signature_type: 'Signature Type',
    signature_types: 'Signature Types',
    signature_type_manual: 'Manual',
    signature_type_sproof: 'Sproof',
    asset_signature_legal_notice: 'Signature Legal Notice',
    contract_type: 'Contract Type',
    contract_type_cash: 'Cash',
    contract_type_voucher: 'Voucher',
    contract_payment_start_date: 'Payment Start Date',
    contract_next_payment_date: 'Next Payment Date',
    payment_date: 'Payment Date',
    contract_period_type_fixed: 'Fixed',
    asset_document_type_0: 'Contract Data',
    asset_document_type_1: 'Reporting',
    asset_document_type_2: 'Consumer Information',
    asset_signature_document: 'Emission Signature Document',
    asset_signature_document_name: 'Emission Zeichnungsschein Name',
    help_asset_signature_document_name: 'Der Emission Zeichnungsschein Name',
    asset_signature_document_description:
        'Emission Zeichnungsschein Beschreibung',
    help_asset_signature_document_description:
        'Die Emission Zeichnungsschein Beschreibung',
    transfer: 'Transfer',
    wallet: 'Wallet',
    amount: 'Amount',
    date: 'Date',
    associate_orders: 'Associate orders',
    campaign: 'Campaign',
    campaigns: 'Campaigns',
    orders: 'Orders',
    order: 'Order',
    created_at: 'Created at',
    cancelled_at: 'Cancelled at',
    user_orders_list: 'Orders List',
    export_asset_investment_report: 'Export investment report',
    export_check_orders_report: 'Export check orders report',
    send_ad_hoc_email_reminder: 'Send ad-hoc email reminder',
    transfer_to_project_wallet: 'Transfer to project wallet',
    open_baskets: 'View incomplete',
    basket_stage: 'Current stage',
    view_campaigns: 'View campaigns',
    status: 'Status',
    sub_status: 'Sub status',
    created_on: 'Created on',
    campaign_title: 'Campaign title',
    campaign_subtitle: 'Campaign subtitle',
    create_campaign: 'Create Campaign',
    campaign_details: 'Campaign details',
    campaign_summary: 'Campaign Summary',
    campaign_excerpt: 'Campaign Excerpt',
    funding_period: 'Funding Period',
    funding_start: 'Funding Start',
    funding_end: 'Funding End',
    regulatory_requirements: 'Regulatory Requirements',
    agio_disagio: 'Agio/Disagio',
    agio_disagio_title: 'Agio/Disagio Title',
    agio_disagio_rate: 'Agio/Disagio Rate',
    agio_disagio_type: 'Agio/Disagio Type',
    agio_disagio_info_text: 'Agio/Disagio Infotext',
    agio_disagio_disclaimer: 'Agio/Disagio Disclaimer',
    agio_disagio_voucher: 'Voucher for Agio/Disagio',
    savings_plan: 'Savings plan',
    savings_plan_description: 'Savings plan description',
    voucher_code: 'Voucher Code',
    voucher_rate: 'Voucher Rate',
    country_and_language: 'Country and language',
    campaign_status: 'Campaign Status',
    funding_stats_visible: 'Display funding status',
    password: 'Password',
    broker: 'Broker',
    broker_status: 'Broker status',
    required_field: 'Required',
    broker_disclaimer: 'Broker Disclaimer',
    external_project_page: 'External project page',
    external_project_url: 'External Project Page Link',
    badge: 'Badge',
    campaign_badge: 'Select badge',
    checkout: 'Checkout',
    show_amount_slider: 'Show amount slider',
    media: 'Media',
    campaign_thumb_image: 'Thumbnail',
    campaign_hero_image: 'Main Image',
    campaign_content: 'Campaign Content',
    main: 'Main',
    account_title: 'Account Title',
    content_images: 'Content images',
    loading: 'Loading',
    content_blocks: 'Content blocks',
    rank: 'Rank',
    title: 'Title',
    team_members: 'Team members',
    job_title: 'Job title',
    incentives: 'Incentives',
    funding: 'Funding',
    campaign_content_block_title: 'Name',
    campaign_content_block_content: 'Content',
    campaign_incentive_amount: 'Incentive amount',
    campaign_incentive_description: 'Incentive reward',
    campaign_incentive_title: 'Incentive  title',
    campaign_incentives_limit: 'Incentive Limit',
    campaign_team_member_name: 'Team member name',
    campaign_team_member_title: 'Team member title',
    campaign_team_member_bio: 'Team member bio',
    campaign_team_member_image: 'Team member picture.',
    wallet_status_title: 'Wallet Status',
    withdrawals: 'Withdrawals',
    withdrawal_amount: 'Amount',
    wallet_money_withdrawals: 'Money Withdrawals from Wallet',
    wallet_status_sc: 'SC Wallet',
    wallet_status_not_opened: 'Not opened',
    wallet_status_incomplete: 'Incomplete',
    wallet_status_rejected: 'Rejected',
    wallet_status_kyc1: 'KYC 1',
    wallet_status_kyc2: 'KYC 2',
    wallet_status_kyc3: 'KYC 3',
    wallet_status_expired: 'Expired',
    wallet_status_blocked: 'Blocked',
    wallet_status_closed: 'Closed',
    wallet_status_kyc_updating_2_3: 'Updating from KYC 2 to 3',
    wallet_status_ont_time_customer: 'One time customer',
    wallet_status_crowdlending: 'Crowdlending Wallet',
    wallet_status_technique: 'Technique Wallet',
    wallet_status_undefined: 'Account has no wallet',
    wallet_withdrawal_started_from_frontend: 'Was Started from Frontend',
    wallet_transactions: 'Wallet transactions',
    no_wallet_transactions: 'No wallet transactions',
    transaction_sender: 'Sender',
    transaction_receiver: 'Receiver',
    transaction_type: 'Transaction Type',
    transactions: 'Transactions',
    account: 'Investor',
    accounts: 'Investors',
    natural_profile_active: 'Active',
    account_first_name: 'First Name',
    account_last_name: 'Last Name',
    account_email: 'Email',
    account_date_of_birth: 'Birthday',
    account_nationality: 'Nationality',
    account_default_language: 'Default language',
    account_email_pending: 'Email pending',
    linked_natural_profiles: 'Linked Natural Profiles',
    natural_profiles: 'Natural Profiles',
    male: 'male',
    female: 'female',
    account_gender: 'Gender',
    account_custom_id: 'Custom ID',
    account_pep: 'Politically exposed person (PEP)',
    account_is_pep: 'yes',
    account_is_not_pep: 'no',
    account_nl_subscribed: 'Accepted Newsletter during signup',
    account_nl_is_subscribed: 'yes',
    account_nl_is_not_subscribed: 'no',
    account_address: 'Address',
    account_address_required_for_wallet: 'Address (Required for Wallet)',
    account_zip: 'Zip Code',
    account_zip_required_for_wallet: 'Zip Code (Required for Wallet)',
    account_country: 'Country',
    account_country_required_for_wallet: 'Country (Required for Wallet)',
    account_city: 'City',
    account_city_required_for_wallet: 'City (Required for Wallet)',
    account_state: 'State',
    account_state_required_for_wallet: 'State (Required for Wallet)',
    account_phone: 'Phone',
    account_mobile: 'Mobile',
    account_primary_email: 'E-Mail',
    account_pending_email: 'Pending E-Mail address',
    account_address_kyc_status: 'Confirm address details (KYC)',
    account_address_kyc_document: 'Address-kyc Documents',
    account_kyc_not_verified: 'KYC Not Verified',
    wallet_not_yet_created: 'Account has no wallet',
    kyc_bank_documents: 'Bank account KYC documents',
    kyc_tax_documents: 'Tax KYC documents',
    social_security_number: 'Social security Number',
    account_kyc_status: 'Confirm id details (KYC)',
    account_iban: 'IBAN',
    account_bic: 'BIC',
    account_bank_accounts: 'Bank accounts',
    account_bank_account: 'Bank account',
    account_bank_account_headline: 'Bank account list',
    bank_accounts_list: 'List of bank accounts',
    no_bank_accounts: 'No bank accounts',
    is_main_bank_account: 'Main bank account',
    account_bank_historical_data: 'Historical data (without lemonway)',
    account_type: 'Type',
    account_status: 'Status',
    account_holder: 'Bank account holder',
    account_owner: 'Owner',
    account_bank_kyc_status: 'Bank account KYC-status',
    account_profile_overview: 'Profile overview',
    account_profile_details: 'Profile details',
    account_address_contact_details: 'Address and contact details',
    account_address_details: 'Address details',
    account_identity_details: 'Identity details',
    button_delete_dsgvo: 'Delete DSGVO data',
    create_natural_account: 'Create natural profile',
    profile_id: 'Profile ID',
    profile_company_name: 'Company name',
    profile_company_company_email: 'Company email',
    profile_company_description: 'Company description',
    profile_company_required_for_wallet: 'Required for wallet',
    profile_company_registration_number: 'Company registration number',
    profile_company_website_url: 'Company website URL',
    profile_company_details_kyc: 'Confirm company details (KYC)',
    profile_company_bank_kyc: 'Confirm bank details (KYC)',
    legal_account_create: 'create legal profile',
    legal_account_show: 'Show legal profile',
    contact_person: 'Contact person',
    address_details: 'Address details',
    bank_details: 'Bank details',
    tax_details: 'Tax details',
    wallet_details: 'Wallet details',
    kyc_documents: 'KYC documents',
    kyc_id_documents: 'ID check KYC documents',
    kyc_legal_documents: 'Confirm company details (KYC)',
    kyc_document_no_comment: 'No comment for this document',
    open_kyc_document: 'open',
    kyc: 'KYC',
    kyc_external: 'External KYC',
    external_kyc_details: 'External KYC details',
    external_kyc_process_status: 'External KYC status',
    kyc_external_id: 'ID',
    kyc_external_external_id: 'External KYC ID',
    kyc_external_status: 'KYC Status',
    kyc_external_created_at: 'Created on',
    kyc_external_updated_at: 'Last updated on',
    kyc_external_natural_profile_overwritten_at:
        'External KYC natural profile overwritten at',
    kyc_external_result_summary_url: 'Download result summary',
    kyc_external_result_data_package_url: 'Download data package',
    button_kyc_reset_external_process: 'Reset external KYC process',
    comment: 'Comment',
    document_id: 'Document id',
    verification_type: 'Verification type',
    verification_status: 'Verification status',
    account_id: 'Account ID',
    account_document_type: 'Document type',
    valid_to: 'Valid until',
    kyc_verification_status: 'Verification status',
    verification_date: 'Verification date',
    external_kyc: 'External KYC',
    manual_kyc: 'Manual KYC',
    open_doc_in_new_tab: 'Open Doc in new tab',
    kyc_document_file: 'KYC document',
    kyc_type_kyc_id: 'Confirm identity details',
    kyc_type_kyc_bank: 'Confirm bank details',
    kyc_type_kyc_address: 'Confirm address details',
    kyc_type_kyc_tax: 'Confirm tax details',
    kyc_address_documents: 'Address KYC documents',
    tax_number: 'Tax number',
    account_social_security_number: 'Social security number',
    account_tax_kyc_status: 'Tax details KYC-status',
    account_show_legal_account: 'Show legal account',
    profile: 'Profile',
    view_profile: 'View profile',
    wallet_balance: 'Balance',
    wallet_retrievable_balance: 'Retrievable balance',
    wallet_blocked_balance_from_paid_orders: 'Blocked (paid orders)',
    wallet_blocked_balance_from_pending_withdrawals:
        'blocked (pending withdrawals)',
    wallet_status: 'Wallet status',
    wallet_currency: 'currency',
    wallet_deposit_information: 'Deposit information',
    wallet_deposit_account_holder: 'Account holder',
    wallet_deposit_iban: 'IBAN',
    wallet_deposit_bic: 'BIC',
    wallet_deposit_payment_reference: 'Payment reference',
    money_withdrawal_value: 'Withdrawal amount',
    yes: 'yes',
    no: 'no',
    search_account_title: 'Search Account',
    nothing_found: 'Nothing found',
    search_other_user: 'Search other user',
    order_send_mails: 'Send order mails',
    date_of_order: 'Date of order',
    edit_order: 'Edit order',
    additional_fields: 'Additional fields',
    order_attachments: 'Order attachments',
    order_attachment_upload: 'Upload order attachment',
    order_documents: 'Order documents',
    reporting_documents: 'Reporting documents',
    documents: 'Documents',
    view_document: 'View document',
    depot: 'Depot',
    depot_owner: 'Depot owner',
    depot_number: 'Depot number',
    depot_bank: 'Depot bank',
    depot_bic: 'Depot bank BIC',
    order_details: 'Order details',
    order_id: 'Order id',
    order_emission_signature: 'Emission signature',
    order_emission_signatures: 'Emission signatures',
    document_type: 'Document type',
    signature_date: 'Signature date',
    signature_cancellation_date: 'Signature cancellation date',
    confirm_manual_signature: 'Confirm manual signature',
    regenerate_signatures: 'Regenerate signatures',
    order_summary: 'Order summary',
    account_name: 'Account name',
    change_order_status: 'Change order status',
    bulk_change_order_status: 'Change order status of multiple orders',
    total: 'Total',
    invested_amount: 'Invested amount',
    wallet_amount: 'Wallet amount',
    total_amount_to_pay: 'Total amount to pay',
    incentive: 'Incentive',
    voucher_amount: 'Voucher amount',
    voucher_balance: 'Voucher balance',
    vouchers: 'Vouchers',
    reference_number: 'Reference',
    order_was_created_manually: 'Created manually',
    profile_information_legal: 'Investor (Legal Profile)',
    profile_legal: 'Legal Profile',
    profile_information_natural: 'Investor (Natural Profile)',
    linked_profile_information_natural: 'Linked natural profile',
    date_of_birth: 'Date of birth',
    nationality: 'Nationality',
    change_status_to: 'Change Status to',
    for_selected_orders: 'for {selected} orders',
    account_name_at_order: 'Account name (at order)',
    account_name_current: 'Account name (current)',
    investor_information_for_germany: 'Investor Information for Germany',
    please_confirm_data: 'Please Confirm Data',
    first_name: 'First name',
    last_name: 'Last name',
    place_of_birth: 'Place of birth',
    id_card_passport_number: 'ID/Passport Number',
    issuing_authority: 'Issuing Authority',
    vib_investment_information:
        'Hiermit bestätige ich, dass ich das Vermögensanlagen-Informationsblatt rechtzeitig zur Verfügung gestellt bekommen sowie heruntergeladen und gespeichert habe. Insbesondere habe ich den Warnhinweis, dass der Erwerb dieser Vermögensanlage mit erheblichen Risiken verbunden ist und bis zum vollständigen Verlust des eingesetzten Vermögens führen kann, zur Kenntnis genommen.',
    vib_investment_under_1000:
        'Hiermit bestätige ich, dass der Gesamtbetrag der von mir erworbenen Vermögensanlagen der „synthesize B2C markets (EM)“ (inklusive meines im Rahmen dieses Investmentangebots gewährten Darlehensbetrages) 1000 Euro nicht übersteigt',
    vib_investment_income:
        'Hiermit bestätige ich, dass der Gesamtbetrag der von mir erworbenen Vermögensanlagen der synthesize B2C markets (EM) (inklusive meines im Rahmen dieses Investmentangebots gewährten Darlehensbetrages) zwischen 10 000 und 25 000 Euro liegt, jedoch den zweifachen Betrag meines durchschnittlichen monatlichen Nettoeinkommens nicht übersteigt.',
    vib_investment_financial_assets_over_10000:
        'Hiermit bestätige ich, dass der Gesamtbetrag der von mir erworbenen Vermögensanlagen der „synthesize B2C markets (EM)“ (inklusive meines im Rahmen dieses Investmentangebots gewährten Darlehensbetrages) zwischen 1 000 und 10 000 Euro liegt und ich über ein frei verfügbares Vermögen in Form von Bankguthaben und Finanzinstrumenten von mindestens 100 000 Euro verfüge.',
    vib_investment_limit:
        'Hiermit bestätige ich, dass ich insgesamt nicht mehr als 25.000 € an Vermögensanlagen des Emittenten „synthesize B2C markets (EM)“ erworben habe (inklusive meines im Rahmen dieses Investmentangebots gewählten Darlehensbetrags).',
    wphg_investment_under_1000:
        'Hiermit bestätige ich, dass meine Investition 1.000 € nicht übersteigt.',
    wphg_investment_limit:
        ' Hiermit bestätige ich, dass ich insgesamt nicht mehr als 25.000 € in Wertpapiere des Emittenten synthesize B2C markets (EM) investiert habe (inklusive meines im Rahmen dieses Angebots gewählten Investments).',
    wphg_inappropriate_investment:
        'Ich bin darüber informiert, dass die Zeichnung dieses Produkts nicht meinem Anlageprofil aufgrund der von mir gemachten Angaben entspricht. Ich entscheide mich dennoch für die Zeichnung dieses Produkts.',
    set_to_canceled: 'Cancel order',
    set_to_paid: 'Set to paid',
    set_to_pending: 'Set to pending',
    set_to_accepted: 'Set to accepted',
    investment: 'Investment',
    bullet: 'Bullet',
    period: 'Period',
    next_payment: 'Next payment',
    interest: 'Interest',
    payouts: 'Payouts',
    contract_duration: 'Contract duration',
    first_payout: 'First Payout',
    last_payout: 'Last Payout',
    total_payments_count: 'Total Payments Count',
    start_of_contract: 'Start of Contract',
    add_date: 'Add payout date',
    early_bird_promotion: 'Early Bird Promotion',
    early_bird_interest_rate: 'Early Bird Interest Rate',
    early_bird_deadline: 'Early Bird Deadline',
    interest_rate_calculation: 'Interest Rate Calculation',
    calculation_method: 'Calculation Method',
    calculation_start_date: 'Calculation Start Date',
    repayment: 'Repayment',
    bullet_loan: 'Bullet Loan',
    deferred_clearance: 'Deferred Clearance',
    external_wallet: 'External Wallet',
    external_wallet_id: 'External Wallet ID',
    wallet_id: 'Wallet ID',
    voucherify_settings: 'Voucherify Settings',
    voucher_wallet: 'Voucher Wallet',
    voucher_campaign: 'Voucher Campaign',
    voucher_campaigns: 'Voucher Campaigns',
    voucher_campaign_name: 'Campaign name',
    add_voucher_campaign: 'Add campaign',
    voucher_campaign_type: 'Campaign type',
    voucher_campaign_default: 'Default',
    voucher_contract: 'Voucher Contract',
    interest_payments: 'Interest Payments',
    paid_interest: 'Paid interest',
    paid_clearance: 'Paid clearance',
    payment: 'Payment',
    total_amount: 'Total amount',
    sum_paid_clearance: 'Sum paid clearance',
    bonus: 'bonus',
    download_sepa_csv: 'Download SEPA CSV',
    start_payment: 'Start payment',
    paid_at: 'Paid at',
    accepted_at: 'Accepted at',
    interest_payment_transactions: 'Interest payment transactions',
    loan: 'Loan',
    clearance: 'clearance',
    rest: 'rest',
    execute_interest_payment: 'Execute interest payment',
    execute_interest_payment_including_sepa_export:
        'Execute interest payment including SEPA export',
    recalculate: 'Recalculate',
    recalculate_and_export_excel: 'Recalculate and export excel',
    recalculate_and_export_sepa_csv: 'Recalculate and export SEPA CSV',
    please_recalculate_interest_first: 'Please recalculate interest first',
    sum_paid_rest: 'Sum paid rest',
    pay_interest: 'Pay interest',
    pay_clearance: 'Pay clearance',
    pay_percent_of_rest: 'Pay percent of rest',
    interest_bonus: 'Interest bonus',
    new_calculation: 'New calculation',
    load_payout_tasks: 'Lad payout tasks',
    start_interest_payment_payout: 'Start interest payment payout',
    started_at: 'Started at',
    finished_at: 'Finished at',
    finished_type: 'Finished_type',
    result: 'Result',
    result_details: 'Result details',
    interest_payment_date: 'Interest payment date',
    settings: 'Settings',
    sign_out: 'Sign out',
    export_accounts_csv: 'Export filtered Accounts-List',
    view: 'View',
    no_table_entries: 'There are no entries.',
    check_table_filters: 'Change filter settings.',
    export_orders_csv: 'Export filtered Orders-List',
    bulk_change_order_campaign: 'Change campaign',
    active: 'Active',
    natural_account: 'Natural account',
    legal_account: 'Legal account',
    no_orders: 'There is no orders.',
    manual_investments: 'Manual investments',
    duplicate: 'Duplicate',
    change_status: 'Change status',
    emails: 'Emails',
    email_template: 'Email Template',
    recipient: 'Recipient',
    change_order_status_date: 'Change status date',
    is_test: 'Test',
    send: 'Send',

    button_bank_kyc_document_upload: 'Upload bank verification document',
    button_address_kyc_document_upload: 'Upload address verification document',
    button_tax_kyc_document_upload: 'Upload tax verification document',
    button_id_kyc_document_upload: 'Upload ID verification document',
    button_legal_kyc_document_upload: 'Upload company verification document',
    button_sync_wallet: 'Sync wallet',
    button_delete_legal: 'Delete account',
    sender: 'Sender',
    receiver: 'Receiver',
    transaction_method: 'Transaction method',
    date_from: 'Date from',
    date_to: 'Date to',
    view_linked_order: 'View linked order',
    view_sender_natural_profile: 'View natural profile of sender',
    view_receiver_natural_profile: 'View natural profile of receiver',
    view_sender_legal_profile: 'View legal profile of sender',
    view_receiver_legal_profile: 'View legal profile of receiver',
    no_signatures: 'There are no signatures.',
    billing_address: 'Billing address',
    postal_address: 'Postal address',
    contact_details: 'Contact details',
    same_as_billing_address: 'Same as billing address',

    help_platform_name: 'The name of the platform.',
    help_parent_platform_name: 'The parent platform.',
    help_platform_url: 'The URL of the platform.',
    help_file_name: 'The name of the file.',
    help_file_type: 'The type of file.',
    help_platform_webhook_event: 'The event to trigger the action.',
    help_platform_webhook_url:
        'The URL to be triggered when the action event occurs.',
    help_platform_webhook_secret:
        'The secret to secure the webhook url. You can only retrieve the secret during creation!',
    help_platform_policy_name: 'The name of the platform policy.',
    help_platform_policy_country: 'The country this policy is valid for.',
    help_platform_policy_language: 'The language of the policies content.',
    help_platform_policy_content: 'The content of the policy.',
    help_platform_email_template_type: 'The type of the email template.',
    help_platform_email_template_language:
        'The language of the email templates content.',
    help_platform_email_template_subject: 'The subject of the email template.',
    help_platform_email_template_content:
        'The content of the email template. To reload the placeholders, clear the type and select the appropriate one.',
    help_platform_disclaimer_name: 'The name of the disclaimer.',
    help_platform_disclaimer_language:
        'The language of the disclaimers content.',
    help_platform_disclaimers_countries_applied:
        'The countries, this disclaimer is applied in.',
    help_platform_disclaimers_countries_not_applied:
        'The countries, this disclaimer is NOT applied in.',
    help_platform_disclaimer_content: 'The content of the platform disclaimer.',
    help_platform_terms:
        'Publicly accessible URL to the terms of service of the platform.',
    help_platform_privacy_url:
        'Publicly accessible URL to the privacy policy of the platform.',
    help_platform_admin_email:
        'Email address used by the platform to send emails.',
    help_platform_mail_template:
        'Email template used for outgoing emails sent by the platform.',
    help_clearing_systems: 'Select one or more clearing systems.',
    help_clearing_system: 'Select a clearing systems.',
    help_order_status_for_total_funding_sum:
        'Select one or more order status that will be considered when calculating the total funding sum.',
    help_mail_host: 'The SMTP server to use when sending emails.',
    help_mail_port:
        'The server port to use when communicating with the SMTP server.',
    help_mail_encryption:
        'The encryption method to use when communicating with the SMTP server.',
    help_mail_from_address: 'The sending email address of outgoing emails.',
    help_mail_form_name: 'The name of the sender of outgoing emails.',
    help_mail_username: 'The username to authenticate to the SMTP server.',
    help_mail_password: 'The password to authenticate to the SMTP server.',
    help_asset_title: 'The title of the asset.',
    help_currency: 'The currency to use.',
    help_asset_base_funding_count:
        'Manually increase the number of investments.',
    help_asset_base_funding_total: 'Manually increase the investment amount.',
    help_asset_category: 'The asset category.',
    help_asset_currency: 'The currency to use on this asset.',
    help_asset_deactivate_payment_account:
        'Should automatic payments be deactivated?',
    help_asset_funding_min: 'The funding minimum.',
    help_asset_funding_max: 'The funding maximum.',
    help_asset_investment_min: 'The minimal amount per investment.',
    help_asset_investment_max: 'The maximal amount per investment.',
    help_asset_manual_funding_totals: 'Use manual investment totals?',
    help_asset_location_city: 'The asset location city.',
    help_asset_location_state: 'The asset location state',
    help_asset_location_country: 'The asset location country.',
    help_asset_status: 'Main status.',
    help_asset_step_level: 'The step levels to follow for investments.',
    help_asset_sub_status: 'Sub status.',
    help_company_name: 'The company name.',
    help_company_logo: 'The company logo to display in the frontend.',
    help_company_address: 'The address of the company.',
    help_company_city: 'The city of the company address.',
    help_company_postal_code: 'The postal code of the company address.',
    help_company_state: 'The state of the company address.',
    help_company_country: 'The country of the company address.',
    help_company_email: 'The email of the company.',
    help_company_phone_number: 'The phone number of the company.',
    help_company_website: 'The website of the company.',
    help_company_iban: 'The IBAN of the company.',
    help_company_bic: 'The BIC of the company.',
    help_contract_range: 'Eg. 3 years, 6 month, ...',
    help_interest_rate: 'The interest rate of the contract',
    help_asset_signature_types: 'The allowed signatures types for the asset.',
    help_asset_signature_legal_notice: 'The legal notice about the signature.',
    help_asset_document_type: 'The type of the asset document.',
    help_asset_document_description: 'Description of the document',
    help_asset_documents_table_0:
        'These asset documents are sent via mail to the investor.',
    help_asset_documents_table_1:
        'The reporting documents are shown in the frontend.',
    help_asset_documents_table_2:
        'These documents are sent via email to the investor before the investment is completed.',
    help_transfer_to_project_wallet_wallet:
        'The wallet to transfer the funds to.',
    help_transfer_to_project_wallet_date:
        'Investments until this date will be transferred.',
    help_campaign_title: 'The title of the campaign',
    help_campaign_subtitle: 'The subtitle of the campaign',
    help_country_campaign:
        'Select the country this campaign will be published.',
    help_language_campaign: 'Select the language of the campaign.',
    help_campaign_summary: 'The summary of the campaign.',
    help_platform_name_campaign:
        'Select the platform this asset is associated with.',
    help_asset_title_campaign:
        'Select the asset this campaign is associated with.',
    help_campaign_excerpt: 'The excerpt of the campaign',
    help_funding_start: 'Start of the Funding of the Campaign',
    help_funding_end: 'End of the Funding of the Campaign',
    help_regulatory_requirements:
        'The regulatory requirements for this campaign.',
    help_agio_disagio: 'Agio/Disagio',
    help_agio_disagio_title: 'The Agio/Disagio title.',
    help_agio_disagio_rate: 'The Agio/Disagio rate.',
    help_agio_disagio_type: 'The Agio/Disagio type.',
    help_agio_disagio_info_text: 'The Agio/Disagio Infotext.',
    help_agio_disagio_disclaimer: 'The Agio/Disagio disclaimer.',
    help_agio_disagio_voucher: 'Enable or disable a voucher for agio/disagio.',
    help_voucher_code: 'The voucher code for the agio/disagio voucher.',
    help_voucher_rate: 'The discount provided by the voucher.',
    help_status_campaign: 'Campaign status.',
    help_funding_stats_visible:
        'Should the funding status be publicly displayed.',
    help_password_campaign:
        'Password to secure the campaign. Only users with access can invest.',
    help_broker_status: 'Should the following disclaimer be shown?',
    help_help_broker_field_required:
        'Should the broker field required in the order process?',
    help_broker_disclaimer: 'The disclaimer to be shown in the frontend.',
    help_external_project_url: 'Link to an external project page.',
    help_campaign_badge: 'Select the badge to show on the campaign header.',
    help_show_amount_slider:
        'Should the amount slider be shown during the checkout.',
    help_campaign_thumb_image: 'The campaign thumbnail',
    help_campaign_hero_image: 'The campaign main image',
    help_main_campaign: 'Campaign main content',
    help_summary_campaign: 'Campaign summary',
    help_associate_orders_modal_text:
        'Do you want to associate all orders that do not belong to a contract with the contract {contract}?',
    help_drag_and_drop: 'Drag and drop items to change the order.',
    help_campaign_content_block_title: 'Title of the content block.',
    help_campaign_content_block_content: 'Content of the content block.',
    help_campaign_incentive_amount: 'The incentive amount.',
    help_campaign_incentive_description: 'The incentive description.',
    help_campaign_incentive_title: 'The incentive title.',
    help_campaign_team_member_name: 'The team member name.',
    help_campaign_team_member_title: 'Title of the team member.',
    help_campaign_team_member_bio: 'The team member bio.',
    help_campaign_team_member_image: 'Picture of the team member.',
    help_order_invested_amount: 'The Invested Amount.',
    help_order_send_mails: 'Send order mails.',
    help_date_of_order: 'The date of order.',
    help_select_campaign: 'Select the campaign.',
    help_select_incentive: 'Select the incentive.',
    help_contract_type: 'The contract type.',
    help_search_account_title: 'Search for the Account.',
    help_search_field: 'Type at least 3 characters into the search field.',
    help_order_attachment_type: 'Select the order attachment type.',
    help_order_attachment_name: ' The order attachment name.',
    help_order_attachment_description: 'The order attachment description.',
    help_additional_fields_enabled:
        'Activate or deactivate the additional fields (All fields are always activated or deactivated). The following switches are for the mandatory entry of the respective fields.',
    help_depot_owner: 'The depot owner.',
    help_depot_number: 'The depot number.',
    help_depot_bank: 'The depot bank.',
    help_depot_bic: 'The Depot BIC.',
    help_email: 'The email address.',
    help_city: 'The city.',
    help_date: 'The date.',
    help_first_name: 'The first name.',
    help_last_name: 'The last name.',
    help_place_of_birth: 'The place of birth.',
    help_date_of_birth: 'The date of Birth.',
    help_id_card_passport_number: 'The ID or Passport Number.',
    help_issuing_authority: 'The Issuing Authority.',
    help_address: 'The address.',
    help_postal_code: 'The postal code.',
    help_state: 'The region.',
    help_tax_number: 'The tax number.',
    help_bulk_change_order_status: 'Select the new status.',
    help_contract_duration: 'Select the duration of the contract.',
    help_first_payout: 'First payout date.',
    help_last_payout: 'Last payout date.',
    help_total_payments_count: 'The number of payouts for the contract.',
    help_start_of_contract: 'Select the start period for the payments.',
    help_early_bird_promotion:
        'Enable or disable the Early Bird Promotion for this contract.',
    help_early_bird_interest_rate:
        'Early Bird Interest rate for the Promotion.',
    help_early_bird_deadline: 'Set the deadline for the early bird promotion.',
    help_calculation_method:
        'Select the calculation method for the interest rate.',
    help_calculation_start_date:
        'Select the date that should be used for the start of the interest rate calculation.',
    help_bullet_loan:
        'Enable or disable repayments at the end of the contract duration.',
    help_deferred_clearance:
        'Number or payments that the repayment is deffered.',
    tax_calculation: 'Tax Calculation (Church Tax)',
    tax_calculation_enabled: 'Enable Tax Calculation',
    help_external_wallet_id: 'Set the external wallet ID for this contract.',
    help_voucher_wallet:
        'The redeemed vouchers are debited from this Lemonway wallet.',
    help_voucher_campaign_name: 'The voucher campaign name.',
    help_voucher_campaign_type: 'The voucher campaign type.',
    help_voucher_campaign_default: 'Set the voucher campaign as default.',
    help_pay_percent_of_rest: 'The payment percent of rest.',
    help_interest_bonus: 'The interest bonus.',
    help_open_file_in_new_tab: 'open file in new tab',
    help_show_verification_comment: 'show comment',
    help_account_first_name: 'First name',
    help_account_last_name: 'Last name',
    help_account_email: 'Email address',
    help_account_nationality: 'Nationality',
    help_account_gender: 'The gender',
    help_account_country: 'The country',
    help_account_address: 'The address',
    help_account_zip: 'The ZIP code',
    help_account_city: 'The city',
    help_account_state: 'The state',
    help_account_phone: 'The phone number',
    help_account_email_pending: 'E-Mail verification',
    help_account_company_description: 'Company description',
    help_account_company_registration_number: 'Company registration number',
    help_account_company_name: 'Company name',
    help_account_name: 'The investor name.',
    help_account_custom_id: 'Individual ID',
    help_campaigns_table: 'The Campaigns table.',
    help_contracts_table: 'The Contracts table.',
    help_bank_account: 'Verified bank account',
    help_money_withdrawal_comment: 'Comment for the withdrawal',
    help_money_withdrawal_amount: 'Amount of the withdrawal',
    help_kyc_document_type: 'Document type',
    help_kyc_document_iban: 'IBAN',
    help_kyc_document_account_holder: 'Bank account holder',
    help_edit_order: 'Edit order',
    help_campaign_content_images_table: 'The Campaign Content Images table.',
    help_campaign_content_blocks_table: 'The Campaign Content Blocks table.',
    help_platform_attachments_table: 'The Platform Attachments table.',
    help_platform_webhooks_table: 'The Platform Webhooks table.',
    help_platform_policies_table: ' The Platform Policies table',
    help_platform_email_templates_table: 'The Platform Email Templates table.',
    help_platform_disclaimers_table: 'The Platform Disclaimers table',
    help_bulk_change_order_campaign: 'Select the new campaign.',
    help_assets_table: 'The Assets table.',
    help_asset_contracts_table: 'The Asset Contracts table.',
    help_platforms_table: 'The platforms.',
    help_orders_table: 'The orders.',
    help_valid_to: 'Valid date must be after the day after tomorrow',
    help_verification_status: 'Verification status',
    help_comment: 'Comment',
    help_investor_modal: 'The investor to execute the action as.',
    help_incentives_table: 'The incentives.',
    help_bank_accounts_table: 'The bank accounts.',
    help_view_order: 'View order',
    help_wallet_transaction_table: 'The wallet transactions table',
    help_withdrawals_table: 'The withdrawals table',
    help_natural_profiles: 'Linked natural profiles',
    help_view_natural_profile: 'View linked natural profile',
    help_accounts_table: 'Accounts',
    help_order_attachments_table: 'Order attachments',
    help_manual_investments:
        'Change manually the count and total_amount of investments.',
    help_emails_table: 'Emails',
    help_team_members_table: 'The team members table.',
    help_order_status: 'Please select the status date you want to change.',
    help_order_status_date:
        'Please select the date you want to change the status date to.',
    help_transactions_table: 'Transactions',
    help_default_language: 'Default language',
    help_savings_plan: 'Activate savings plan Email',
    help_savings_plan_description:
        'This text will appear in investment process summary',

    modal_title_export_result: 'Export',
    modal_title_delete_platform: 'Delete platform?',
    modal_title_delete_platforms: 'Delete platforms?',
    modal_title_update_platform_attachment: 'Update platform attachment',
    modal_title_create_platform_attachment: 'Create platform attachment',
    modal_title_delete_platform_attachment: 'Delete platform attachment?',
    modal_title_delete_platform_attachments: 'Delete platform attachments?',
    modal_title_update_platform_webhook: 'Update platform webhook',
    modal_title_create_platform_webhook: 'Create platform webhook',
    modal_title_delete_platform_webhook: 'Delete platform webhook?',
    modal_title_delete_platform_webhooks: 'Delete platform webhoooks?',
    modal_title_update_platform_policy: 'Update platform policy',
    modal_title_create_platform_policy: 'Create platform policy',
    modal_title_delete_platform_policy: 'Delete platform policy?',
    modal_title_delete_platform_policies: 'Delete platform policies?',
    modal_title_update_platform_email_template:
        'Update platform email template',
    modal_title_create_platform_email_template:
        'Create platform email template',
    modal_title_delete_platform_email_template:
        'Delete platform email template?',
    modal_title_delete_platform_email_templates:
        'Delete platform email templates?',
    modal_title_update_platform_disclaimer: 'Update platform disclaimer',
    modal_title_create_platform_disclaimer: 'Create platform disclaimer',
    modal_title_delete_platform_disclaimer: 'Delete platform disclaimer?',
    modal_title_delete_platform_disclaimers: 'Delete platform disclaimers?',
    modal_title_delete_asset: 'Delete Asset?',
    modal_title_delete_assets: 'Delete Assets?',
    modal_title_update_asset_document: 'Update asset document',
    modal_title_create_asset_document: 'Create asset document',
    modal_title_update_ecsp_document: 'Edit ECSP Document',
    modal_title_view_ecsp_document: 'View ECSP Document',
    modal_title_update_asset_document_0:
        'Update asset document (contract detail)',
    modal_title_create_asset_document_0:
        'Create asset document (contract detail)',
    modal_title_update_asset_document_1: 'Update asset document (reporting)',
    modal_title_create_asset_document_1: 'Create asset document (reporting)',
    modal_title_update_asset_document_2:
        'Update asset document (consumer information)',
    modal_title_create_asset_document_2:
        'Create asset document (consumer information)',
    modal_title_delete_asset_document: 'Delete asset document?',
    modal_title_delete_asset_documents: 'Delete asset documents?',
    modal_title_transfer_to_project_wallet: 'Transfer to project wallet',
    modal_title_delete_campaign: 'Delete campaign?',
    modal_title_delete_campaigns: 'Delete campaigns?',
    modal_title_associate_orders: 'Associate orders?',
    modal_title_delete_asset_contract: 'Delete asset contract?',
    modal_title_delete_asset_contracts: 'Delete asset contracts?',
    modal_title_delete_campaign_content_image: 'Delete content image?',
    modal_title_delete_campaign_content_images: 'Delete content images?',
    modal_title_delete_campaign_content_block: 'Delete content block?',
    modal_title_delete_campaign_content_blocks: 'Delete content blocks?',
    modal_title_view_campaign_content_block: 'View content block',
    modal_title_edit_campaign_content_block: 'Edit content block',
    modal_title_create_campaign_content_block: 'Create content block',
    modal_title_view_campaign_team_member: 'View team member',
    modal_title_create_campaign_team_member: 'Create team member',
    modal_title_edit_campaign_team_member: 'Edit team member',
    modal_title_delete_campaign_team_member: 'Delete team member?',
    modal_title_view_campaign_incentive: 'View incentive',
    modal_title_edit_campaign_incentive: 'Edit incentive',
    modal_title_create_campaign_incentive: 'Create incentive',
    modal_title_delete_campaign_incentive: 'Delete incentive?',
    modal_title_delete_campaign_incentives: 'Delete incentives?',
    modal_title_create_order: 'Create order',
    modal_title_edit_order_attachment: 'Edit order attachment',
    modal_title_create_order_attachment: 'Create order attachment',
    modal_title_delete_order_attachment: 'Delete order attachment?',
    modal_title_delete_order_attachments: 'Delete order attachments?',
    modal_title_confirm_signature: 'Confirm signature',
    modal_title_cancel_order: 'Cancel Order?',
    modal_title_accept_order: 'Accept this Order?',
    modal_title_set_order_status_to_pending: 'Set order status to pending?',
    modal_title_set_order_status_to_paid: 'Set Order Status to PAID?',
    modal_title_delete_contract: 'Delete contract?',
    modal_title_delete_contracts: 'Delete contracts?',
    modal_title_delete_task: 'Delete task?',
    modal_title_confirm_update_contract: 'Confirm update contract',
    modal_title_new_interest_payment_calculation:
        'New interest payment calculation',
    modal_title_execute_interest_payment: 'Execute interest payment?',
    modal_title_payout_task: 'Payout task',
    modal_title_start_interest_payment_payout: 'Start interest payment payout?',
    modal_title_create_money_withdrawal: 'Create money withdrawal',
    modal_title_show_kyc_document_comment: 'Document comments',
    modal_title_create_bank_kyc_document:
        'Upload KYC document for bank account',
    modal_title_create_address_kyc_document:
        'Upload KYC document for address verification',
    modal_title_create_tax_kyc_document:
        'Upload KYC document for tax verification',
    modal_title_create_legal_kyc_document:
        'Upload KYC document for company details confirmation',
    modal_title_bulk_change_order_status: 'Bulk change order status',
    modal_title_bulk_change_order_campaign: 'Bulk change order campaign',
    modal_title_delete_natural_profile: 'Delete DSGVO data?',
    modal_title_update_kyc_document: 'Update KYC Document',
    modal_title_create_id_kyc_document:
        'Upload KYC Document for ID verification',
    modal_title_create_nautral_account: 'Create natural profile',
    modal_title_change_campaign_status: 'Change campaing status',
    modal_title_change_order_status_date: 'Change order status date',

    modal_text_confirm_signature:
        'Are you sure you want to manually confirm the signature? This should only be done after a legally binding document has been received and reviewed.',
    modal_text_regenerate_signature:
        'Are you sure you want to regenerate the signatures? All currently active signatures will get cancelled.',
    modal_text_order_status_set_order_status_paid:
        'YES, set Order Status to PAID.',
    modal_text_order_status_accept: 'YES, ACCEPT this order.',
    modal_text_order_status_set_order_status_pending:
        'YES, set Order Status to PENDING.',
    modal_text_order_status_set_order_status_canceled:
        'YES, set Order Status to CANCELED.',
    modal_text_confirm_update_contract:
        'The contract with id {contract} has orders. Do you want to updated this contract?',
    modal_text_execute_interest_payment:
        'Are you sure to execute this Interest Payment? This cannot be undone.',
    modal_text_start_interest_payment_payout:
        'Are you sure that the interest payment payouts should be made to the wallets?',
    modal_text_delete_natural_profile:
        'Are you sure you want to delete all information about this account. This GDPR deletion deletes account, kyc documents, audits, ... of this account. This also deletes all other accounts with the same email address in the same platform. (old versions of this account)',
    modal_text_delete_legal_profile: 'Please confirm the deletion of account.',
    modal_text_delete_task:
        'Do you realy want to delete the task "{type}" which was created on {created}?',
    // eslint-disable-next-line quotes
    toast_error_cannot_delete_platform: "Can't delete this platform!",
    toast_error_access_denied: 'You are not allowed to open page: {path}.',
    toast_success_platform_deleted: 'Platform "{platform}" deleted.',
    toast_success_platforms_deleted: 'Platforms "{platforms}" deleted.',
    toast_success_applying_filters: 'Applying filters: {filters}',
    toast_success_clear_filters: 'Clear filters ...',
    toast_success_platform_created: 'Platform created!',
    toast_success_platform_saved: 'Platform "{platform}" saved!',
    toast_success_platform_attachment_created:
        'Platform attachment "{attachment}" created!',
    toast_success_platform_attachment_updated:
        'Platform attachment "{attachment}" updated!',
    toast_success_platform_attachment_deleted:
        'Platform attachment "{attachment}" deleted!',
    toast_success_platform_attachments_deleted:
        'Platform attachments "{attachments}" deleted!',
    toast_success_platform_webhook_created:
        'Platform webhook "{action}" created!',
    toast_success_platform_webhook_updated:
        'Platform webhook "{action}" updated!',
    toast_success_platform_webhook_deleted:
        'Platform webhook "{action}" deleted!',
    toast_success_platform_webhooks_deleted:
        'Platform webhooks "{actions}" deleted!',
    toast_success_platform_policy_created:
        'Platform policy "{policy}" created!',
    toast_success_platform_policy_updated:
        'Platform policy "{policy}" updated!',
    toast_success_platform_policy_deleted:
        'Platform policy "{policy}" deleted!',
    toast_success_platform_policies_deleted:
        'Platform policies "{policies}" deleted!',
    toast_success_platform_email_template_updated:
        'Platform email template "{template}" updated!',
    toast_success_platform_email_template_created:
        'Platform email template "{template}" created!',
    toast_success_platform_email_template_deleted:
        'Platform email template "{template}" deleted!',
    toast_success_platform_email_templates_deleted:
        'Platform email templates "{templates}" deleted!',
    toast_success_platform_email_template_sent:
        'Platform email template "{template}" sent!',
    toast_success_platform_disclaimer_updated:
        'Platform disclaimer "{disclaimer}" updated!',
    toast_success_platform_disclaimer_created:
        'Platform disclaimer "{disclaimer}" created!',
    toast_success_platform_disclaimer_deleted:
        'Platform disclaimer "{disclaimer}" deleted!',
    toast_success_platform_disclaimers_deleted:
        'Platform disclaimers "{disclaimers}" deleted!',
    toast_success_asset_created: 'Asset created!',
    toast_error_cannot_delete_asset: 'Asset could not be removed!',
    toast_success_asset_deleted: 'Asset removed successfully!',
    toast_success_assets_deleted: 'Assets were removed successfully!',
    toast_success_campaign_created: 'Campaign created!',
    toast_success_campaign_duplicated: 'Campaign duplicated!',
    toast_success_asset_saved: 'Asset was saved successfully!',
    toast_success_campaign_saved: 'Campaign was saved successfully!',
    toast_success_asset_document_updated:
        'Asset document "{document}" updated!',
    toast_success_asset_document_created:
        'Asset document "{document}" created!',
    toast_success_asset_document_deleted:
        'Asset document "{document}" deleted!',
    toast_success_asset_documents_deleted:
        'Asset documents "{documents}" deleted!',
    toast_success_ad_hoc_email_sent: 'Ad-hoc email sent!',
    toast_success_transfer_to_project_wallet:
        'Start transfer to project wallet: {log}',
    toast_success_associate_orders:
        'Associated {orders} orders with contract {contract}.',
    toast_success_campaign_content_image_deleted:
        'Content image {contentImage} deleted!',
    toast_success_campaign_content_images_deleted:
        'Content images {contentImages} deleted!',
    toast_success_campaign_content_image_created:
        'Content image {contentImage} created!',
    toast_success_campaign_content_block_updated:
        'Content block {contentBlock} updated!',
    toast_success_campaign_content_block_created:
        'Content block {contentBlock} created!',
    toast_success_campaign_content_block_deleted:
        'Content block {contentBlock} deleted!',
    toast_success_campaign_content_blocks_deleted:
        'Content blocks {contentBlocks} deleted!',
    toast_success_order_attachment_deleted:
        'Order attachments {attachment} deleted!',
    toast_success_order_attachments_deleted:
        'Order attachments {attachments} deleted!',
    toast_success_order_attachment_updated:
        'Order attachment {attachment} updated',
    toast_success_order_attachment_created:
        'Order attachment {attachment} created',
    toast_success_order_status_changed: 'Order {order} status changed!',
    toast_success_order_updated: 'Order {order} updated!',
    toast_success_order_created: 'Order created!',
    toast_success_orders_status_changed: 'Orders {orders} status changed!',
    toast_success_campaign_deleted: 'Campaign successfully removed!',
    toast_success_campaigns_deleted: 'Campaigns successfully removed!',
    toast_success_contract_saved: 'Contract {contract} updated',
    toast_success_contract_deleted: 'Contract {contract} successfully removed!',
    toast_success_contracts_deleted:
        'Contracts {contracts} successfully removed!',
    toast_success_interest_payment_execute:
        'Interest Payment created successfully!',
    toast_success_interest_payment_payout_started: 'Payment payout started!',
    toast_success_withdrawal_created: 'Withdrawal added successfully!',
    toast_error_withdrawal_creation_failed: 'Withdrawal creation failed!',
    toast_success_natural_profile_updated:
        'Natural Profile update successfull!',
    toast_error_natural_profile_update_failed: 'Natural Profile update failed!',
    toast_success_kyc_document_created: 'Upload successfull!',
    toast_error_kyc_document_create: 'Upload failed!',
    toast_success_legal_profile_updated: 'Legal profile updated successfully',
    toast_error_legal_profile_update_failed: 'Legal profile update failed!',
    toast_error_wallet_sync_failed: 'Wallet sync failed!',
    toast_success_wallet_synced: 'Wallet sync successfull!',
    toast_success_orders_campaign_changed: 'Orders {orders} campaign changed!',
    toast_success_natural_profile_deleted: 'DSGVO deleted!',
    toast_success_legal_profile_deleted: 'Account deleted!',
    toast_success_legal_account_created: 'Legal Account created!',
    toast_success_account_created: 'Acocunt created!',
    toast_success_kyc_document_updated: 'KYC document updated successfully!',
    toast_success_campaign_status_changed:
        'Campaign status changed successfully!',

    toast_error_kyc_document_updated: 'KYC document update failed!',
    toast_success_campaign_incentive_created:
        'Campaign incentive created successfully!',
    toast_success_campaign_incentive_updated:
        'Campaign incentive updated successfully!',

    warning_failed_transactions_count:
        'Warning: Failed transactions count: {count}.',
    warning_missing_iban:
        'Warning: No IBAN was specified for {count} order(s).',
    warning_missing_bic: 'Note: No BIC was specified for {count} order(s).',
    warning_order_failed_transactions_count:
        'Failed transactions count: {count}',
    warning_order_missing_iban: 'No IBAN',
    warning_order_missing_bic: 'No BIC',

    // Enums //

    // CampaignStatus //
    DRAFT: 'Draft',
    ANNOUNCEMENT: 'Announcement',
    PRE_FUNDING: 'Pre-Funding',
    FUNDING: 'Funding',
    FUNDING_SUCCESSFUL: 'Successful',
    FUNDING_UNSUCCESSFUL: 'Unsuccessful',
    PRIVATE_PLACEMENT: 'Private placement',

    // RegulatoryRequirements //
    DEFAULT: 'Default',
    ALTFG: 'AltFG',
    VERMANLG: 'VermAnlG',
    WPHG: 'WpHG-Aktien',
    ECSP: 'ECSP',

    // AgioDisagio //
    DEACTIVATED: 'Disabled',
    AGIO_ACTIVATED: 'Agio',
    DISAGIO_ACTIVATED: 'Disagio',

    // Contract type
    CASH: 'Cash',
    VOUCHER: 'Voucher',

    // Campaign Badge
    NEW: 'Neu',
    WE_LOVE_THIS: 'We love this',
    FAVORITE: 'Favorite',
    STARTING_SOON: 'Started bald',
    IMPACT_INVESTMENT: 'Impact Investment',

    // Order Attachment Type
    INVESTMENT_DOCUMENT: 'Investment document',
    OTHER: 'Other',

    // SignatureDocumentType
    EMISSION: 'Emission',
    ESAC_FORM_A: 'Anlegerprofil (FORM A)',
    ESAC_FORM_B: 'Erklärung des wirtschaftlich Berechtigten (FORM B)',
    ESAC_FORM_C: 'FATCA-Erklärung (Form C)',
    ESAC_FORM_D: 'FATCA-Erklärung (Form D)',

    // SignatureType
    MANUAL: 'Manual',
    SPROOF: 'Sproof',

    // OrderStatus
    CANCELED: 'Canceled',
    PENDING: 'Pending',
    PAID: 'Paid',
    ACCEPTED: 'Accepted',

    // ContractPeriodType
    FIXED: 'Fixed',
    CONTINUOUS: 'Continuous',

    // ContractPeriodStart
    CURRENT: 'current',
    NEXT: 'next',

    // InterestRateCalculationMethod
    IR_ACT_360: 'ACT/360',
    IR_ACT_365: 'ACT/365',
    IR_30_360: '30/360',

    // CalculationStartDate
    ACCEPTANCE_DATE: 'Acceptance date',
    PAID_DATE: 'Paid date',

    // PayoutTaskFinishedType
    SUCCESS: 'success',
    WARNING: 'warning',
    HAS_ERRORS: 'has-errors',
    ERROR: 'error',
    ERROR_WHILE_GENERATING: 'Error while generating',

    // Accounts
    MALE: 'male',
    FEMALE: 'female',
    NON_BINARY: 'non binary',

    // ECSP Status
    EXPERT: 'expert',
    NON_EXPERT: 'non-expert (rejected)',
    EXPERT_PENDING: 'non-expert (need to be checked)',
    NOTHING_TO_CHECK: 'nothing to check',

    // KYC Document Type
    PASSPORT: 'passport',
    INVOICE: 'invoice',
    ID_CARD: 'id card',
    RESIDENCE_CONFIRMATION: 'residence confirmation',
    BANK_STATEMENT: 'bank statement',
    SDD_MANDATE: 'sdd mandate',
    COMPANY_REGISTRATION: 'company registration',
    ARTICLES_OF_ASSOCIATION: 'articles of association',
    KYC_OTHER: 'other',

    // KYC Document Status
    KYC_DOC_STATUS_IN_PROGRESS: 'in progress',
    KYC_DOC_STATUS_ON_HOLD: 'on hold',
    KYC_DOC_STATUS_ACCEPTED: 'accepted',
    KYC_DOC_STATUS_NOT_ACCEPTED: 'rejected',
    KYC_DOC_STATUS_UNREADABLE: 'unreadable',
    KYC_DOC_STATUS_EXPIRED: 'expired',
    KYC_DOC_STATUS_WRONG_TYPE: 'wrong type',
    KYC_DOC_STATUS_WRONG_NAME: 'wrong name',
    KYC_DOC_STATUS_DUPLICATE_DOCUMENT: 'duplicate document',

    // Bank Account Status
    VERIFICATION_PENDING: 'verification pending',
    VERIFIED: 'verified',
    REJECTED: 'rejected',

    // MONEY WITHDRAWAL STATUS
    WITHDRAWAL_PENDING: 'Pending',
    WITHDRAWAL_DONE: 'Done',
    WITHDRAWAL_REJECTED: 'Rejected',

    // PrimaryUserRoles
    DEVELOPER: 'Developer',
    SUPERADMIN: 'Superadmin',
    PLATFORM_ADMIN: 'Platform Admin',
    FRONTOFFICE_MANAGER: 'Frontoffice Manager',
    BACKOFFICE_MANAGER_CONDA: 'Backoffice Manager Conda',
    BACKOFFICE_MANAGER_WL: 'Backoffice Manager WL',
    READ_ONLY: 'Read only',

    WALLET_STATUS_SC: 'SC Wallet',
    WALLET_STATUS_NOT_OPENED: 'Not opened',
    WALLET_STATUS_INCOMPLETE: 'Incomplete',
    WALLET_STATUS_REJECTED: 'Rejected',
    WALLET_STATUS_KYC1: 'KYC 1',
    WALLET_STATUS_KYC2: 'KYC 2',
    WALLET_STATUS_KYC3: 'KYC 3',
    WALLET_STATUS_EXPIRED: 'Expired',
    WALLET_STATUS_BLOCKED: 'Blocked',
    WALLET_STATUS_CLOSED: 'Closed',
    WALLET_STATUS_KYC_UPDATING_2_3: 'Updating from KYC 2 to 3',
    WALLET_STATUS_ONE_TIME_CUSTOMER: 'One time customer',
    WALLET_STATUS_CROWDLENDING: 'Crowdlending Wallet',
    WALLET_STATUS_TECHNIQUE: 'Technique Wallet',
    WALLET_STATUS_UNDEFINED: 'Account has no wallet',

    // ACCOUNT TYPE
    LEGAL: 'legal',
    NATURAL: 'natural',

    // EXTERNAL KYC STATUS
    EXTERNAL_KYC_CREATED: 'created',
    EXTERNAL_KYC_PENDING: 'pending',
    EXTERNAL_KYC_IN_REVIEW: 'in_review',
    EXTERNAL_KYC_SUCCESS: 'success',
    EXTERNAL_KYC_DONE: 'done',
    EXTERNAL_KYC_FAILED_CANCELED: 'failed_canceled',
    EXTERNAL_KYC_FAILED_FRAUDULENT: 'failed_fraudulent',

    // PAYMENT METHODS
    PAYMENT_METHOD_BANK_CARD: 'Bank Card',
    PAYMENT_METHOD_INCOMING_WIRE_TRANSFER: 'Incoming Wire Transfer',
    PAYMENT_METHOD_OUTGOING_WIRE_TRANSFER: 'Outgoing Wire Transfer',
    PAYMENT_METHOD_P2P: 'P2P',
    PAYMENT_METHOD_IDEAL: 'iDEAL',
    PAYMENT_METHOD_SEPA_DIRECT: 'SEPA Direct Debit',
    PAYMENT_METHOD_CHEQUE: 'Cheque',
    PAYMENT_METHOD_NEOSURF: 'Neosurf',
    PAYMENT_METHOD_SOFORT: 'SOFORT',
    PAYMENT_METHOD_PFS_PHYSICAL_CARD: 'PFS Physical Card',
    PAYMENT_METHOD_MULTIBANCO: 'Multibanco',
    PAYMENT_METHOD_PAYSHOP: 'Payshop',
    PAYMENT_METHOD_MB_WAY: 'MB WAY',
    PAYMENT_METHOD_POLISH_INSTANT_TRANSFER: 'Polish Instant Transfer',
    PAYMENT_METHOD_PAGARE: 'Pagare',
    PAYMENT_METHOD_MOBILE_PAY: 'Mobile Pay',
    PAYMENT_METHOD_PAY_TRAIL: 'PayTrail',
    PAYMENT_METHOD_WE_CHAT: 'WeChat (via PayTend)',
    PAYMENT_METHOD_P24: 'P24',
    PAYMENT_METHOD_MONEY_IN_BY_TPE: 'Money In by TPE',
    PAYMENT_METHOD_TRUSTLY: 'Trustly',

    // OPEN BASKET STEPS
    STEP_1_INSTALMENT: 'Step 1 (CF - Instalment)',
    STEP_1: 'Step 1',
    STEP_2: 'Step 2',
    STEP_3: 'Step 3',
    STEP_4: 'Step 4',

    // TRANSACTION TYPES
    MONEY_IN: 'Money In',
    MONEY_OUT: 'Money Out',
    P2P: 'Transfer',

    // INTERNAL TRANSACTION STATUS
    SUCCESS_MONEY_IN_OUT_OR_PENDING_P2P: 'Successful',
    SUCCESS_P2P_OR_MONEY_IN_OUT_ERROR: 'Successful',
    CANCELLED: 'Cancelled',
    TRANSACTION_ERROR: 'Transaction error',
    WAIT_FOR_VALIDATION_FINALIZATION: 'Pending',
    // E-MAILS
    EMAIL_TEMPLATE_WELCOME: 'Welcome',
    EMAIL_TEMPLATE_NEWSLETTER_CONSENT: 'E-Mail Newsletter Consent',
    EMAIL_TEMPLATE_CONSUMER_INFORMATION: 'Consumer Information',
    EMAIL_TEMPLATE_ACCEPTANCE_NOTICE: 'Acceptance Notice',
    EMAIL_TEMPLATE_PAYMENT_CONFIRMATION: 'Payment Confirmation',
    EMAIL_TEMPLATE_PAYMENT_DETAILS: 'Payment Details',
    EMAIL_TEMPLATE_PAYMENT_REMINDER: 'Payment Reminder',
    EMAIL_TEMPLATE_SAVINGS_PLAN_PAYMENT_REMINDER:
        'Savings Plan Payment Reminder',
    EMAIL_TEMPLATE_KYC_REMINDER: 'KYC Reminder',
    EMAIL_TEMPLATE_KYC_STATUS: 'KYC Status Change',
    EMAIL_TEMPLATE_BASKET_REMINDER_ONE: 'Incomplete Order Reminder 1',
    EMAIL_TEMPLATE_BASKET_REMINDER_TWO: 'Incomplete Order Reminder 2',
    EMAIL_TEMPLATE_AUTH_EMAIL_ADDRESS_CHANGED: 'Email Change Notification',
    EMAIL_TEMPLATE_AUTH_EMAIL_VERIFICATION: 'Email Change Verification',
    EMAIL_TEMPLATE_BANK_ACCOUNT_INFORMATION_REMINDER:
        'Bank Account Information Reminder',
    EMAIL_TEMPLATE_PRE_CONSUMER_INFORMATION: 'Pre Consumer Information',
    EMAIL_TEMPLATE_SIGNATURE_REMINDER: 'Signature Reminder',
    EMAIL_TEMPLATE_SIGNATURES_REGENERATED: 'Signature Regenerated',
    EMAIL_TEMPLATE_SIGNATURE_SUCCESSFUL: 'Signature Successful',
    EMAIL_TEMPLATE_EXTERNAL_KYC_STATUS_PENDING:
        'External KYC Status Notification - Pending',
    EMAIL_TEMPLATE_EXTERNAL_KYC_STATUS_SUCCESS:
        'External KYC Status Notification - Success',
    EMAIL_TEMPLATE_EXTERNAL_KYC_PROCESS_ABORTED: 'External KYC Process aborted',
    EMAIL_TEMPLATE_KYC_EXPIRE_REMINDER: 'KYC Expire Reminder',
    EMAIL_TEMPLATE_MONEY_WITHDRAWAL_VERIFICATION:
        'Money Withdrawal - Verification',
    EMAIL_TEMPLATE_INTEREST_PAYMENT_ANNOUNCEMENT:
        'Interest Payment - Announcement',
    EMAIL_TEMPLATE_INTEREST_PAYMENT_PAYOUT_RECEIVED:
        'Interest Payment - Payout Received',
    EMAIL_TEMPLATE_EMPTY_OPTION: 'No Email Template',
    EMAIL_TEMPLATE_VOUCHER_RECEIVED: 'Voucher Received',
    EMAIL_TEMPLATE_NO_IBAN_REMINDER: 'Ad-hoc IBAN-Reminder',
    EMAIL_TEMPLATE_FEEDBACK_REVIEW: 'Feedback / Review',
    EMAIL_TEMPLATE_VOUCHER_BALANCE: 'Voucher Balance Notification',
    EMAIL_TEMPLATE_WALLET_BALANCE: 'Wallet Balance Notification',
    EPC: 'EPC',
    SWISS: 'Swiss',

    // Platform Policies
    TERMS_OF_SERVICE: 'Terms of Service',
    COOKIE_POLICY: 'Cookie Policy',

    // Asset Categories
    STARTUP: 'Startup',
    SME: 'SME',
    REAL_ESTATE: 'Real Estate',
    SPECIAL: 'Special',

    // IssuerIndustry
    AGRICULTURE: 'Agriculture',
    ENERGY_AND_UTILITIES: 'Energy and utilities',
    MINING: 'Mining',
    CONSTRUCTION: 'Construction',
    INDUSTRY: 'Industry',
    TRADE_AND_RETAIL: 'Trade and retail',
    HOSPITALITY_AND_TOURISM: 'Hospitality and tourism',
    TRANSPORTATION_AND_LOGISTICS: 'Transportation and logistics',
    FINANCE_AND_INSURANCE: 'Finance and insurance',
    INFORMATION_TECHNOLOGY: 'Information Technology',
    HEALTHCARE: 'Healthcare',
    EDUCATION_AND_RESEARCH: 'Education and research',

    // IssuerStatus
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    IN_LIQUIDATION: 'In liquidation',
    INSOLVENT: 'Insolvent',
    BUSINESS_LICENSES_DELETED: 'Business licenses deleted',

    // IssuerStatusInternal
    //ACTIVE: 'Active',
    //INACTIVE: 'Inactive',
    SETTLED: 'Settled',
    UNDER_OBSERVATION: 'Under observation',

    //AssetSubStatus
    REPORTING_DELAY: 'Reporting Delay',
    PAYMENT_OUTSTANDING: 'Payment Outstanding',
    REPAID: 'Repaid',
    EARLY_REPAYMENT: 'Early Repayment',

    // AgioDisagioType
    PERCENT: 'Percent',
    FIXED_VALUE: 'Fixed value',
    FIXED_VALUE_PER_SHARE: 'Fixed value per share',

    // NaturalProfileActive
    NATURAL_PROFILE_ACTIVE: 'Natural Profile active',
    NATURAL_PROFILE_DEACTIVATED: 'Natural Profile deactivated',

    // VoucherType
    GIFT_VOUCHER: 'Gift voucher',
    LOYALTY_CARD: 'Loyalty card',
    DISCOUNT_VOUCHER: 'Discount voucher',

    // End of Enums //

    // Languages
    de: 'German',
    en: 'English',
    fr: 'French',
    it: 'Italian',
    pl: 'Polish',
    sl: 'Slovenian',
    sk: 'Slovak',
    sr: 'Serbian',

    // New translations
    help_platform_logo: 'The platform logo',
    active_emails: 'Active emails',
    mail_active_acceptance_notice: 'Mail active acceptance notice',
    help_mail_active_acceptance_notice: 'The mail active acceptance notice',
    mail_active_bank_account_information_reminder:
        'Mail active bank account information reminder',
    help_mail_active_bank_account_information_reminder:
        'The mail active bank account information reminder',
    mail_active_basket_reminder: 'Mail active basket reminder',
    help_mail_active_basket_reminder: 'The mail active basket reminder',
    mail_active_consumer_information: 'Mail active consumer information',
    help_mail_active_consumer_information: 'Mail active consumer information',
    mail_active_external_kyc_status_pending:
        'Mail active external kyc status pending',
    help_mail_active_external_kyc_status_pending:
        'The mail active external kyc status pending',
    mail_active_external_kyc_status_success:
        'Mail active external kyc status success',
    help_mail_active_external_kyc_status_success:
        'The mail active external kyc status success',
    mail_active_external_kyc_process_aborted:
        'Mail active external kyc process aborted',
    help_mail_active_external_kyc_process_aborted:
        'The mail active external kyc process aborted',
    mail_active_kyc_expire_reminder: 'Mail active KYC Expire Reminder',
    help_mail_active_kyc_expire_reminder: 'The mail active KYC Expire Reminder',
    mail_active_interest_payment_announcement:
        'Mail active interest payment announcement',
    help_mail_active_interest_payment_announcement:
        'The mail active interest payment announcement',
    mail_active_interest_payment_payout_received:
        'Mail active interest payment payout received',
    help_mail_active_interest_payment_payout_received:
        'The mail active interest payment payout received',
    mail_active_kyc_reminder: 'Mail active kyc reminder',
    help_mail_active_kyc_reminder: 'The mail active kyc reminder',
    mail_active_kyc_status: 'Mail active kyc status',
    help_mail_active_kyc_status: 'The mail active kyc status',
    mail_active_no_iban_reminder: 'Mail active no iban reminder',
    help_mail_active_no_iban_reminder: 'The mail active no iban reminder',
    mail_active_payment_confirmation: 'Mail active payment confirmation',
    help_mail_active_payment_confirmation: 'Mail active payment confirmation',
    mail_active_payment_details: 'Mail active payment details',
    help_mail_active_payment_details: 'The mail active payment details',
    mail_active_payment_reminder: 'Mail active payment reminder',
    help_mail_active_payment_reminder: 'The mail active payment reminder',
    mail_active_pre_consumer_information:
        'Mail active pre consumer information',
    help_mail_active_pre_consumer_information:
        'The mail active pre consumer information',
    mail_active_signature_reminder: 'Mail active signature reminder',
    help_mail_active_signature_reminder: 'The mail active signature reminder',
    mail_active_signature_successful: 'Mail active signature successful',
    help_mail_active_signature_successful: 'Mail active signature successful',
    mail_active_signatures_regenerated: 'Mail active signatures regenerated',
    help_mail_active_signatures_regenerated:
        'The mail active signatures regenerated',
    mail_active_voucher_received: 'Mail active voucher received',
    help_mail_active_voucher_received: 'The mail active voucher received',
    mail_active_welcome: 'Welcome',
    help_mail_active_welcome: ' The mail mail active welcome',
    mail_active_feedback_review: 'Feedback / Review',
    help_mail_active_feedback_review: 'Request for feedback after investment.',
    mail_active_voucher_balance: 'Voucher Balance Notification',
    help_mail_active_voucher_balance:
        'Information about available voucher balance.',
    mail_active_wallet_balance: 'Wallet Balance Notification',
    help_mail_active_wallet_balance:
        'Information about available wallet balance.',

    digital_signature_types: 'Digital signature types',
    require_qualified_signature: 'Require qualified signature',
    help_require_qualified_signature: 'Require qualified signature',

    qr_code_type: 'QR-Code Type',
    payment_qr_code_type: 'Payment qr code type',
    help_payment_qr_code_type: 'The payment qr code type',

    payment_methods: 'Payment Methods',
    credit_card: 'Credit card',
    wire_transfer: 'Wire transfer',

    confirm_kyc_company_details: 'Confirm KYC company details',
    reject_kyc_company_details: 'Reject KYC company details',
    toast_success_update_legal_profile_kyc_legal_confirmed: 'KYC confirmed',
    toast_success_update_legal_profile_kyc_legal_rejected: 'KYC rejected',
    toast_error_update_legal_profile_kyc_legal: 'KYC update failed',

    help_order_documents_table: 'Documents related to the order.',
    help_order_reporting_documents_table:
        'Reporting documents related to the order.',
    help_kyc_id_documents: 'KYC ID documents',
    help_kyc_address_documents: 'KYC address documents',
    help_kyc_bank_documents: 'KYC bank documents',
    help_kyc_tax_documents: 'KYC tax documents',
    help_kyc_legal_documents: 'Company details for KYC.',
    esac_kyc: 'ESAC KYC',
    esac_kyc_active: 'Esac kyc active',
    help_esac_kyc_active: 'Set esac kyc active.',
    esac_form_natural_person: 'Esac form natural person',
    esac_form_legal_entity: 'Esac form legal entity',
    esac_form_legal_ubo: 'Esac form legal ubo',
    self_disclosure_form: 'Upload Self-Disclosure Form',
    modal_title_delete_file: 'Delete file?',
    modal_text_delete_file: 'Are you sure you want to delete {file}?',

    mail_type_standard: 'Standard',
    help_mail_template: 'The email template to use.',
    mail_template_color: 'Email template color',
    help_mail_template_color: 'General color for platform emails.',
    mail_template_action_button_color: 'Email template action button color',
    help_mail_template_action_button_color:
        'The action button color for platform emails.',
    mail_template_action_button_text_color:
        'Email template action button text color',
    help_mail_template_action_button_text_color:
        'The action button text color for platform emails.',

    is_realm: 'Realm',
    is_platform: 'Plattform',

    platform_assets: 'Platform Assets',
    help_platform_assets: 'The platform assets',
    toast_success_delete_asset: 'Asset deleted successfully',
    platform_campaigns: 'Platform Campaigns',
    help_platform_campaigns: 'The platform campaigns',
    toast_success_delete_campaign: 'Campaign deleted successfully',
    platform_orders: 'Platform Orders',

    asset_video: 'Video URL',
    help_asset_video: 'The video URL for the campaign.',

    toast_success_delete_task: 'Task deleted successfully',

    toast_success_delete_asset_contract: 'Contract deleted successfully',

    acceptance_document: 'Acceptance Document',
    acceptance_document_active: 'Acceptance Document',
    help_acceptance_document:
        'The acceptance document is automatically sent to the investors when investments are accepted.',
    help_acceptance_document_active: 'Activate the Acceptance Document',
    help_platform_orders: 'The Orders of the Platform.',
    no_signature_link_available: 'No signature link available',
    country_of_residence: 'Country of Residence',
    natural_profile_uid: 'Natural Profile UID',
    legal_profile_uid: 'Legal Profile UID',

    upload: 'Upload',

    account_pep_past: 'PEP past',
    account_pep_position: 'PEP position',
    help_account_pep_position: 'The PEP position',
    account_pep_past_position: 'PEP past position',
    help_account_pep_past_position: 'The PEP position',

    modal_title_duplicate_campaign: 'Duplicate campaign',

    expand: 'Expand',
    collapse: 'Collapse',

    tasks: 'Tasks',
    help_tasks_table: 'The Tasks table',
    taskable: 'Taskable',
    result_data: 'Result data',
    result_text: 'Result text',
    expires_at: 'Expires at',
    download: 'Download',

    // Task Type
    GENERIC: 'Generic',
    INTEREST_PAYMENT_CALCULATION: 'Interest payment calculation',
    INTEREST_PAYMENT_PAYOUT: 'Interest payment payout',
    PAYMENT_ACCEPTANCE: 'Payment acceptance',
    EXPORT_CSV: 'Export CSV',

    // TaskStatus
    REGISTERED: 'Registered',
    STARTED: 'Started',
    FINISHED: 'Finished',
    issuer: 'Issuer',
    issuers: 'Issuers',
    issuer_name: 'Name',
    issuer_logo: 'Logo',
    issuer_website: 'Website',
    internal_status: 'Internal Status',
    issuer_details: 'Issuer Details',
    toast_error_cannot_delete_issuer: "Can't delete this issuer!",
    toast_success_issuer_deleted: 'Issuer "{issuer}" deleted!',
    toast_success_issuers_deleted: 'Issuers "{issuers}" deleted!',
    toast_success_issuer_created: 'Issuers created!',
    help_issuers_table: 'The issuers table.',
    modal_title_delete_issuer: 'Delete issuer?',
    create_issuer: 'Create Issuer',
    help_issuer: 'The issuer of the asset',
    help_issuer_name: 'The name of the issuer.',
    help_issuer_logo: 'The company logo of the issuer.',
    issuer_status: 'Status',
    help_issuer_status: 'The status of the issuer.',
    issuer_internal_status: 'Internal Status',
    help_issuer_internal_status: 'The internal status of the issuer.',
    issuer_industry: 'Industry',
    help_issuer_industry: 'The industry of the issuer.',
    toast_success_issuer_saved: 'Issuer {issuer} saved!',

    toast_success_export_csv:
        'The CSV Export will be generated in the background. You will see the result in the "Tasks" section once it is ready.',
    platform_compliance_profile: 'Plattform Compliance Profil',
    end_contract: 'End Contract',
    modal_title_end_contract: 'End Contract?',
    modal_text_end_contract:
        'Are you sure you want to end this contract early?',
    contract_end_cause: 'Contract end cause',
    help_contract_end_cause: 'The Contract end cause',
    toast_success_end_contract: 'Contract ended sucessfully!',

    help_signatures_table: 'The Signatures table',

    beneficial_owners: 'Beneficial owners',
    help_beneficial_owners: 'The Beneficial owners',
    show_signatures: 'Show signatures',
    toast_success_cancel_order: 'Order canceled successfully!',

    help_open_basket_table: 'Incomplete orders are displayed here',
    export_transactions: 'Export transactions',
    toast_success_export_transactions: 'Transactions exported successfully!',
    export_kista_request_kistama: 'Export KISTA request (tax number available)',
    export_kista_request_kistakom:
        'Export KISTA request (tax number not available)',
    toast_success_export_kista_request: 'KISTA request successfully exported',
    toast_success_kista_response_import:
        'KISTA response successfully imported. Generated tax features: {newTaxFeaturesCount}',
    upload_kista_response: 'Upload KISTA response CSV',

    modal_title_task_details: 'Task details',

    total_count: 'Total count',
    error_count: 'Error count',
    modal_title_duplicate_cash_contract: 'Duplicate cash contract',
    modal_text_duplicate_cash_contract: 'Duplicate cash contract?',

    has_orders: 'Has orders',

    copy_value: 'Copy value',
    toast_success_copy_to_clipboard: 'Value copied to the clipboard!',
    toast_error_copy_to_clipboard: 'Copy failed, please try again.',
    order_paid_date: 'Paid date',
    order_acceptance_date: 'Acceptance date',
    order_cancellation_date: 'Cancellation am',

    need_help: 'Need help?',
    documentation: 'Documentation',
    report_a_bug: 'Report a bug',
    give_feedback: 'Give feedback',

    resend_mail: 'Resend order email',
    modal_title_resend_mail: 'Resend order email?',
    help_resend_mail: 'Choose the email to resend.',

    is_main: 'Is main',

    modal_title_view_kyc_document: 'View KYC document.',
    modal_title_view_kyc_bank_document: 'View KYC Bank document.',

    created_by: 'Created by',
    payment_account_id: 'Payment account id',
    funding_total_status: 'Funding total status',
    webhooks_activated: 'Webhooks activated',
    has_credential_simply_tokenized: 'Has Simply Tokenized credentials',
    has_credential_sproof: 'Has sproof credentials',
    has_credential_voucherify: 'Has voucherify credentials',
    compliance_profile: 'Compliance profile',
    expert_investor_treatment: 'Sophisticated investor?',

    updated_at: 'Updated at',
    investor_consent: 'Investor consent',
    contract_period: 'Contract period',
    asset_funding_count: 'Funding count',
    asset_funding_total: 'Funding total',
    asset_installment_investment: 'Installment investment',
    asset_initial_payment: 'Initial Payment',
    asset_installment_payout: 'Installment payout',
    asset_minimum_initial_payment: 'Minimum initial payment',
    help_asset_minimum_initial_payment: 'Minimum initial payment',
    asset_maximum_initial_payment: 'Maximum initial payment',
    help_asset_maximum_initial_payment: 'Maximum initial payment',
    asset_initial_payment_step: 'Initial payment step',
    help_asset_initial_payment_step: 'Initial payment step',
    asset_installments: 'Instalments',
    asset_minimum_installment: 'Minimum installment',
    help_asset_minimum_installment: 'Minimum installment',
    asset_maximum_installment: 'Maximum installment',
    help_asset_maximum_installment: 'Maximum installment',
    asset_installment_step: 'Installment step',
    help_asset_installment_step: 'Installment step',
    asset_minimum_monthly_rates: 'Minimum monthly rates',
    help_asset_minimum_monthly_rates: 'Minimum monthly rates',
    asset_maximum_monthly_rates: 'Maximum monthly rates',
    help_asset_maximum_monthly_rates: 'Maximum monthly rates',
    asset_minimum_yearly_rates: 'Minimum yearly rates',
    help_asset_minimum_yearly_rates: 'Minimum yearly rates',
    asset_maximum_yearly_rates: 'Maximum yearly rates',
    help_asset_maximum_yearly_rates: 'Maximum yearly rates',
    asset_monthly_payments: 'Monthly payments',

    campaign_asset_needs_contract_selection: 'Asset needs contract selection',

    ended_at: 'Ended at',
    payment_dates: 'Payment dates',
    confirmed: 'Confirmed',

    last_login_date: 'Last login date',
    blocked_balance_from_orders: 'Blocked balance from orders',
    sso_user_uid: 'SSO User UID',
    custom_id: 'Custom id',
    sub_platform: 'Sub platform',
    email_pending: 'Email pending',
    mobile_number: 'Mobile number',
    newsletter_consent: 'Newsletter consent',
    help_contract_payment_dates_title: 'Please add payout dates',
    help_contract_payment_dates_text:
        'Please provide payout dates for the contract. Please make sure that these dates are after the first payout and before the last payout for "Fixed" contracts.',

    installments: 'Installments',
    disabled_on: 'Disabled on',
    current_loan_amount: 'Current loan amount',
    paid_total: 'Paid total',
    paid_total_gross: 'Paid total gross',
    paid_total_net: 'Paid total net',
    total_tax: 'Total tax',
    church_tax: 'Church tax',
    soli: 'Soli',
    tax: 'Tax',
    paid_rest: 'Paid rest',
    paid_bonus: 'Paid bonus',
    cumulative_rest: 'Cumulative rest',
    current_rest: 'Current rest',
    new_basis: 'New basis',
    search: 'Search',
    global_search_placeholder: 'Global search...',
    more_search_results: 'View more results...',
    no_languages_available_for_this_email_template:
        'No languages available for this email template.',

    subtasks: 'Subtasks',

    ecsp_status: 'ECSP Status',
    uploaded_document: 'Uploaded document',
    ecsp_document: 'ECSP document',
    ecsp_documents: 'ECSP documents',
    button_id_ecsp_document_upload: 'Upload ECSP verification document',
    modal_title_create_id_ecsp_document:
        'Upload ECSP Document for verification',
    verification_comment: 'Verification comment',
    toast_error_ecsp_document_create: 'ECSP document could not be saved!',
    toast_success_ecsp_document_updated: 'ECSP document updated successfully!',
    ecsp_id_document: 'Uploaded ECSP Document',
    help_ecsp_id_document:
        'In this table you can manage the ECSP document, tied to the compliance profile ECSP.',
    help_ecsp_status: 'Status of the ECSP verification',

    kyc_provider: 'KYC Provider',
    the_field_contains_unallowed_characters:
        'The field contains unallowed characters.',

    //St. Pauli
    membership_management: 'Membership management',
    membership_management_active: 'Activate membership management',
    help_membership_management_active:
        'Activate the functionality of membership management',
    membership_type_manual: 'Manual',
    membership_type_sproof: 'Sproof',
    qualified_signature: 'Qualified signature',
    help_qualified_signature: 'Activate qualified signiture',
    use_rule: 'Use Rule',
    help_use_rule:
        'If this switch is enabled, only orders from investors who have an accepted membership document can be accepted.',
    membership_number_active: 'Activate membership number',
    help_membership_number_active:
        'Activate membership number in membership management',
    membership_number_prefix_natural:
        'Membership number prefix for natural profile',
    help_membership_number_prefix_natural:
        'Here you can specify which character the membership number belonging to the natural profile should start with',
    membership_number_prefix_legal:
        'Membership number prefix for legal profile',
    help_membership_number_prefix_legal:
        'Here you can specify which character the membership number belonging to the legal profile should start with',
    help_membership_number: 'Membership number',
    membership_barcode_active: 'Activate barcode',
    help_membership_barcode_active: 'Activate barcode functionality',
    upload_document: 'Upload document',
    membership_number: 'Membership number',
    membership_id_document: 'Uploaded membership document',
    help_membership_id_document: 'Here you can upload a membership document',
    modal_title_create_id_membership_document: 'Upload new membership document',
    button_id_membership_document_upload: 'Select document',
    modal_title_update_membership_document: 'Edit membership document',
    membership_document_status: 'status of the membership document',
    membership_document_description: 'Membership document description',
    help_membership_document_description:
        'Here you can enter a description for the member document. Will be displayed for customers in the Member Document area. This is to help customers know what to do with the document.',
    help_membership_document_status:
        'The status of the membership document can have 3 values: pending, accepted and rejected',
    has_membership: 'Member',
    hasnt_membership: 'Not member',
    modal_title_view_membership_document: 'Membership document',
    toast_success_membership_document_updated:
        'Membership document updated successfully!',
    toast_error_membership_document_updated:
        'Membership document could not be saved!',
    toast_error_platform_saving_membership_document_missing:
        'Platform with active membership management without membership document cannot be saved!',
    toast_success_membership_document_create:
        'Membership document updated successfully!',
    toast_error_membership_document_create:
        'Membership document could not be saved!',
    toast_success_membership_document_approved:
        'Membership document approved successfully!',
    toast_error_membership_document_approved:
        'Membership document could not be approved!',

    upload_nyala_registry_extract: 'Upload Nyala Registry Extract',
    toast_success_upload_nyala_registry_extract:
        'Documents uploaded successfully for the following customers: {externalCustomerIds}.',
    send_nyala_registry_document_email: 'Send Nyala Registry Document email',
    toast_success_send_nyala_registry_document_email:
        'The Nyala Registry Document email was successfully sent.',
    add: 'Add',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    toast_success_kyc_processes_reset: 'KYC processes reset successfully!',
    toast_error_kyc_processes_reset: 'KYC processes could not be reset!',
    EMAIL_TEMPLATE_NYALA_REGISTRY_FILE: 'Send Nyala Registry Documents',
    nyala_registry_documents: 'Nyala Registry Documents',
    nyala_registry_documents_help: 'The Nyala Registry Documents',
    nyala_registry_document: 'Nyala Registry Document',
}
