<template>
    <div>
        <div class="mb-2 flex items-start justify-between">
            <span class="font-bold">{{ $t('document_id') }}:</span>
            <span>{{ modelValue.id }}</span>
        </div>
        <div class="flex items-start justify-between">
            <span class="font-bold">{{ $t('file') }}:</span>
            <a
                :href="(modelValue.url as string)"
                target="_blank"
                class="text-blue-500"
            >
                {{ $t('open_doc_in_new_tab') }}
            </a>
        </div>
    </div>

    <div>
        <SelectField
            v-model="modelValue.status"
            name="membership_document_status"
            label="membership_document_status"
            help="help_membership_document_status"
            :options="mapEnumToArray(MembershipDocumentStatusType)"
            :reduce="reduceEnumValue"
            :required="true"
        />
    </div>
</template>
<script setup lang="ts">
import { useEnum } from '@composables/common'
import { SelectField } from '@src/components'
import { MembershipDocumentStatusType } from '@src/enums'
import { ECSPDocument } from '@src/types'
import { Ref, toRefs } from 'vue'

const props = defineProps<{ modelValue: Ref<ECSPDocument> }>()

const { modelValue } = toRefs(props)
const { mapEnumToArray, reduceEnumValue } = useEnum()
</script>
