export enum NaturalProfileGenderType {
    MALE = 'male',
    FEMALE = 'female',
    NON_BINARY = 'non-binary',
}

export enum EcspStatusType {
    EXPERT = 'expert',
    NON_EXPERT = 'non_expert',
    NOTHING_TO_CHECK = 'nothing_to_check',
    EXPERT_PENDING = 'expert_pending',
}

export enum MembershipDocumentStatusType {
    ACCEPTED = 'accepted',
    PENDING = 'pending',
    REJECTED = 'rejected',
}

export enum ProfileType {
    LEGAL = 'legal',
    NATURAL = 'natural',
}

export enum KYCDocumentType {
    PASSPORT = 'passport',
    INVOICE = 'invoice',
    ID_CARD = 'id-card',
    RESIDENCE_CONFIRMATION = 'residence-confirmation',
    BANK_STATEMENT = 'bank-statement',
    SDD_MANDATE = 'sdd-mandate',
    COMPANY_REGISTRATION = 'company-registration',
    ARTICLES_OF_ASSOCIATION = 'articles-of-association',
    KYC_OTHER = 'other',
}

export enum KYCLegalDocumentType {
    PASSPORT = 'passport',
    ID_CARD = 'id-card',
    COMPANY_REGISTRATION = 'company-registration',
    ARTICLES_OF_ASSOCIATION = 'articles-of-association',
    KYC_OTHER = 'other',
}

export enum KYCBankDocumentType {
    BANK_STATEMENT = 'bank-statement',
}

export enum KYCAddressDocumentType {
    RESIDENCE_CONFIRMATION = 'residence-confirmation',
}

export enum KYCIdDocumentType {
    PASSPORT = 'passport',
    ID_CARD = 'id-card',
}

export enum KYCTaxDocumentType {
    INVOICE = 'invoice',
}

export enum KycDocumentStatus {
    KYC_DOC_STATUS_IN_PROGRESS = 'in-progress',
    KYC_DOC_STATUS_ON_HOLD = 'on-hold',
    KYC_DOC_STATUS_ACCEPTED = 'accepted',
    KYC_DOC_STATUS_NOT_ACCEPTED = 'rejected',
    KYC_DOC_STATUS_UNREADABLE = 'unreadable',
    KYC_DOC_STATUS_EXPIRED = 'expired',
    KYC_DOC_STATUS_WRONG_TYPE = 'wrong-type',
    KYC_DOC_STATUS_WRONG_NAME = 'wrong-name',
    KYC_DOC_STATUS_DUPLICATE_DOCUMENT = 'duplicate-document',
}

export enum BankAccountStatus {
    VERIFICATION_PENDING = 'verification-pending',
    VERIFIED = 'verified',
    REJECTED = 'rejected',
    EXPIRED = 'expired',
}

export enum MoneyWithdrawalStatus {
    WITHDRAWAL_PENDING = 'pending',
    WITHDRAWAL_DONE = 'done',
    WITHDRAWAL_REJECTED = 'canceled',
}

export enum WalletStatus {
    WALLET_STATUS_SC = -1,
    WALLET_STATUS_NOT_OPENED = 1,
    WALLET_STATUS_INCOMPLETE = 2,
    WALLET_STATUS_REJECTED = 3,
    WALLET_STATUS_KYC1 = 5,
    WALLET_STATUS_KYC2 = 6,
    WALLET_STATUS_KYC3 = 7,
    WALLET_STATUS_EXPIRED = 8,
    WALLET_STATUS_BLOCKED = 10,
    WALLET_STATUS_CLOSED = 12,
    WALLET_STATUS_KYC_UPDATING_2_3 = 13,
    WALLET_STATUS_ONE_TIME_CUSTOMER = 14,
    WALLET_STATUS_CROWDLENDING = 15,
    WALLET_STATUS_TECHNIQUE = 16,
}
export enum AccountType {
    LEGAL = 'legal',
    NATURAL = 'natural',
}

export enum ExternalKycStatus {
    EXTERNAL_KYC_CREATED = 'created',
    EXTERNAL_KYC_PENDING = 'pending',
    EXTERNAL_KYC_IN_REVIEW = 'in_review',
    EXTERNAL_KYC_SUCCESS = 'success',
    EXTERNAL_KYC_DONE = 'done',
    EXTERNAL_KYC_FAILED_CANCELED = 'failed_canceled',
    EXTERNAL_KYC_FAILED_FRAUDULENT = 'failed_fraudulent',
}

export enum NaturalProfileActive {
    NATURAL_PROFILE_ACTIVE = 'true',
    NATURAL_PROFILE_DEACTIVATED = 'false',
}
