<template>
    <AssetsTable>
        <template #actions>
            <DeleteBulkButton
                v-if="userStore.can('delete_asset')"
                :selected="selected"
                @click.stop="openBulkDeleteModal"
            />
            <CreateButton
                v-if="userStore.can('create_asset')"
                @click.stop="openCreateModal"
            />
        </template>
        <template #filters>
            <DropdownFilter
                :pin="pinFilters"
                @apply="applyFilters"
                @clear="clearFilters"
                @toggle-pin="togglePinFilters"
            >
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.platform_name_exact"
                            v-model:options="filterPlatformOptions"
                            name="platform_name"
                            class="w-full"
                            :placeholder="$t('platform')"
                            :on-search="loadPlatformNameOptions"
                            :disabled="disableFilter('platform_name_exact')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.asset_title"
                            name="asset_title"
                            :placeholder="$t('asset_name')"
                            :disabled="disableFilter('asset_title')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.status"
                            name="status"
                            class="w-full"
                            :options="mapEnumToArray(AssetStatus)"
                            :placeholder="$t('status')"
                            :disabled="disableFilter('status')"
                            @update:model-value="retrieveAssetSubStatus"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.sub_status"
                            name="sub_status"
                            class="w-full"
                            :options="mapEnumToArray(assetSubStatuses)"
                            :placeholder="$t('sub_status')"
                            :disabled="
                                filtersFields.status === AssetStatus.ACTIVE ||
                                filtersFields.status === AssetStatus.INACTIVE
                            "
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.currency"
                            name="currency"
                            class="w-full"
                            :options="currencyOptions"
                            :placeholder="$t('currency')"
                            :disabled="disableFilter('currency')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.clearing_system"
                            name="clearing_system"
                            class="w-full"
                            :options="['CI', 'CF', 'EMISSION']"
                            :placeholder="$t('clearing_system')"
                            :disabled="disableFilter('clearing_system')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.created_on"
                            v-model:dateFrom="filtersFields.date_from"
                            v-model:dateTo="filtersFields.date_to"
                            placeholder="created_on"
                            name="filter_created_on"
                            datemode="range"
                            :disabled="disableFilter('created_on')"
                        />
                    </label>
                </DropdownFilterItem>
            </DropdownFilter>
        </template>
    </AssetsTable>

    <CreateAssetModal
        v-if="createModalOpen"
        ref="createAssetModal"
        v-model="tmpAsset"
        v-model:open="createModalOpen"
        :disabled-fields="createDisabledFields"
        @submit="createAsset"
        @cancel="closeCreateModal"
    />
    <DeleteItemModal
        id="delete-asset-modal"
        ref="deleteAssetModal"
        v-model="tmpAsset"
        v-model:open="deleteModalOpen"
        title="modal_title_delete_asset"
        name-key="asset_title"
        id-key="asset_uid"
        @submit="deleteAsset"
        @cancel="closeDeleteModal"
    />
    <DeleteItemsModal
        id="delete-assets-modal"
        ref="deleteAssetsModal"
        v-model="assetsToDelete"
        v-model:open="bulkDeleteModalOpen"
        title="modal_title_delete_assets"
        id-key="asset_uid"
        name-key="asset_title"
        @submit="bulkDeleteAssets"
        @cancel="closeBulkDeleteModal"
    />
</template>
<script setup lang="ts">
import { useEnum, useTable } from '@composables/common'
import PlatformNameCellRenderer from '@partials/platforms/PlatformNameCellRenderer.vue'
import {
    Datepicker,
    DeleteItemModal,
    DeleteItemsModal,
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import DeleteBulkButton from '@src/partials/actions/DeleteBulkButton.vue'
import CreateAssetModal from '@src/partials/assets/CreateAssetModal.vue'
import { Asset, Platform, TableSorting } from '@src/types'
import {
    createAsset as apiCreateAsset,
    deleteAsset as apiDeleteAsset,
    deleteAssets,
    getAssets,
    getAssetSubStatus,
} from '@src/utils/api/assets'
import { getCurrencies } from '@src/utils/api/currencies'
import { getPlatforms } from '@src/utils/api/platforms'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import moment from 'moment'
import { computed, onMounted, ref, shallowRef, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { AssetStatus, AssetSubStatus } from '@src/enums'

const props = withDefaults(
    defineProps<{
        initialFilters?: {
            asset_title?: string | null
            currency?: string | null
            platform_name_exact?: string | null
            clearing_system?: string | null
            issuer_id?: string | null
        } | null
        createInitialValues?: Asset | null
        createDisabledFields?: string[] | null
    }>(),
    {
        initialFilters: null,
        createInitialValues: null,
        createDisabledFields: null,
    }
)

const { initialFilters, createInitialValues, createDisabledFields } =
    toRefs(props)

const router = useRouter()
const toast = useToastStore()
const i18n = useI18n()
const { mapEnumToArray, getEnumLabel } = useEnum()

const assets = ref<Asset[]>([])
const tmpAsset = ref<Asset>(new Asset())
const count = ref<number>(0)

const filterPlatformOptions = ref<string[]>([])
const createModalOpen = ref<boolean>(false)
const bulkDeleteModalOpen = ref<boolean>(false)
const deleteModalOpen = ref<boolean>(false)
const deleteAssetModal = ref(null)
const deleteAssetsModal = ref(null)
const currencyOptions = ref<string[]>([])
const assetSubStatuses = ref<AssetSubStatus[]>([])

const userStore = useUserStore()

const disableFilter = (filter: string) => {
    if (initialFilters.value) {
        return (
            Object.keys(initialFilters.value).filter((item) => {
                return item === filter
            }).length > 0
        )
    }
    return false
}

const {
    TableWrapper: AssetsTable,
    selected,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    setFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadAssets,
    filters: {
        asset_title: null,
        currency: null,
        platform_name_exact: null,
        clearing_system: null,
        issuer_id: null,
        search: null,
    },
    disableFilters: initialFilters.value
        ? Object.keys(initialFilters.value)
        : [],
    tableProps: {
        id: 'assets-table',
        label: 'assets',
        help: 'help_assets_table',
        pagination: true,
        data: assets,
        total: count,
        sorting: ref<TableSorting[]>([
            {
                field: 'created_on',
                direction: 'desc',
            },
        ]),
        columns: [
            {
                label: 'id',
                key: 'asset_uid',
                select: true,
            },
            {
                label: 'id',
                key: 'asset_uid',
                url: assetsTableUrl,
                sorting: true,
                condition: userStore.can('see_id_columns'),
                copy: true,
            },
            {
                label: 'name',
                key: 'asset_title',
                url: assetsTableUrl,
                sorting: true,
                copy: true,
            },
            {
                label: 'platform',
                key: 'platform_name',
                cellRenderer: shallowRef(PlatformNameCellRenderer),
                sorting: true,
            },
            {
                label: 'status',
                key: 'status',
                cellRenderer: (props: { rowData: Asset }) => {
                    return getEnumLabel(AssetStatus, props.rowData.status, true)
                },
                sorting: true,
            },
            {
                label: 'sub_status',
                key: 'sub_status',
                cellRenderer: (props: { rowData: Asset }) => {
                    return props.rowData.sub_status !== null
                        ? getEnumLabel(
                              AssetSubStatus,
                              props.rowData.sub_status,
                              true
                          )
                        : '-'
                },
                sorting: true,
            },
            {
                label: 'currency',
                key: 'currency',
                sorting: true,
            },

            {
                label: 'created_on',
                key: 'created_on',
                sorting: true,
                cellRenderer: (props: { rowData: Asset }) => {
                    return moment(props.rowData.created_on).format('DD.MM.yyyy')
                },
            },
        ],
        actions: [
            {
                action: handleViewAsset,
                icon: 'fa-eye',
                title: 'view',
                condition: userStore.can('view_assets'),
            },
            {
                action: handleEditAsset,
                icon: 'fa-solid fa-pen',
                title: 'edit',
                condition: userStore.can('update_asset'),
            },
            {
                action: handleDeleteAsset,
                icon: 'fas fa-trash-alt',
                title: 'delete',
                color: 'text-danger-500',
                condition: userStore.can('delete_asset'),
            },
        ],
    },
})

function assetsTableUrl(asset: Asset) {
    return '/assets/' + asset.asset_uid
}

function handleViewAsset(asset: Asset) {
    router.push({ name: 'asset', params: { id: asset.asset_uid } })
}

function handleEditAsset(asset: Asset) {
    router.push({ name: 'asset.edit', params: { id: asset.asset_uid } })
}

function openBulkDeleteModal(): void {
    bulkDeleteModalOpen.value = true
}

function openCreateModal(): void {
    if (createInitialValues.value) {
        tmpAsset.value = {
            ...tmpAsset.value,
            ...createInitialValues.value,
        }
    }
    createModalOpen.value = true
}

async function loadAssets() {
    const getAssetsRequest = await getAssets(
        filters.value,
        page.value,
        limit.value,
        sorting.value
    )
    assets.value = getAssetsRequest.data.assets
    count.value = getAssetsRequest.data.assets_count
}

async function loadPlatformNameOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    filterPlatformOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

async function createAsset(next?: string) {
    const createAssetRequest = await apiCreateAsset(tmpAsset.value)
    const { asset_uid } = createAssetRequest.data.asset

    createModalOpen.value = false

    if (next && next == 'edit') {
        router.push({
            name: 'asset.edit',
            params: { id: asset_uid },
        })
    } else if (next && next == 'view') {
        router.push({ name: 'asset', params: { id: asset_uid } })
    } else {
        page.value = 1
        limit.value = parseInt(import.meta.env.VITE_DEFAULT_TABLE_LIMIT)
        await loadAssets()
    }

    tmpAsset.value = new Asset()

    toast.success(i18n.t('toast_success_asset_created'))
}

function closeCreateModal() {
    tmpAsset.value = new Asset()
    createModalOpen.value = false
}

function handleDeleteAsset(asset: Asset) {
    tmpAsset.value = asset
    deleteModalOpen.value = true
}

async function deleteAsset(asset: Asset) {
    if (!asset.asset_uid) {
        toast.error(i18n.t('toast_error_cannot_delete_asset'))
        return
    }

    await apiDeleteAsset(asset.asset_uid)

    deleteModalOpen.value = false

    tmpAsset.value = new Asset()

    toast.success(
        i18n.t('toast_success_asset_deleted', { asset: asset.asset_title })
    )

    page.value = 1
    await loadAssets()
}

function closeDeleteModal() {
    tmpAsset.value = new Asset()
}

async function bulkDeleteAssets() {
    await deleteAssets(selected.value)

    toast.success(
        i18n.t('toast_success_assets_deleted', {
            assets: selected.value.join(', '),
        })
    )

    bulkDeleteModalOpen.value = false

    selected.value = []

    loadAssets()
}

async function retrieveAssetSubStatus() {
    if (filtersFields.value.status) {
        assetSubStatuses.value = []
        const assetSubStatusRequest = await getAssetSubStatus(
            filtersFields.value.status
        )
        if (assetSubStatusRequest.data.success) {
            assetSubStatuses.value = assetSubStatusRequest.data.sub_status ?? {}
            if (
                assetSubStatuses.value === null ||
                Object.values(assetSubStatuses.value).indexOf(
                    filtersFields.value.sub_status
                ) === -1
            ) {
                filtersFields.value.sub_status = null
            }
        }
    }
}

function closeBulkDeleteModal() {
    deleteModalOpen.value = false
}

const assetsToDelete = computed(() => {
    return assets.value.filter(
        (asset) => asset.asset_uid && selected.value.includes(asset.asset_uid)
    )
})

onMounted(async () => {
    if (initialFilters.value) {
        filtersFields.value = {
            ...filtersFields.value,
            ...initialFilters.value,
        }
        if (filtersFields.value.platform_name_exact) {
            await loadPlatformNameOptions(
                filtersFields.value.platform_name_exact
            )
        }
        setFilters()
    }
    await loadAssets()
    const currenciesRequest = await getCurrencies()
    currencyOptions.value = currenciesRequest.data.currencies
})
</script>
<style lang="scss" scoped>
input {
    &:disabled {
        @apply disabled:bg-gray-100;
    }
}
</style>
