import { VoucherCampaignType } from '@src/enums'
import {
    PlatformComplianceProfile,
    PlatformPolicyType,
    PlatformWebhookAction,
} from '@src/enums/Platform'
import { ImageInterface } from '@src/types'

export class Platform {
    platform_uid: string | null = null
    parent_platform_name: string | null = null
    platform_name: string | null = null
    platform_url: string | null = null
    platform_logo: ImageInterface | null = null
    terms_url: string | null = null
    privacy_url: string | null = null
    admin_email: string | null = null
    created_by: string | null = null
    created_on: string | null = null
    updated_at: string | null = null
    url_pattern: string | null = null
    mail_template: 'standard' | null = null
    mail_template_color: string | null = null
    mail_template_action_button_color: string | null = null
    mail_template_action_button_text_color: string | null = null
    mail_active_acceptance_notice = false
    mail_active_bank_account_information_reminder = false
    mail_active_basket_reminder = false
    mail_active_consumer_information = false
    mail_active_external_kyc_status_pending = false
    mail_active_external_kyc_status_success = false
    mail_active_external_kyc_process_aborted = false
    mail_active_kyc_reminder = false
    mail_active_kyc_status = false
    mail_active_kyc_expire_reminder = false
    mail_active_no_iban_reminder = false
    mail_active_payment_confirmation = false
    mail_active_payment_details = false
    mail_active_payment_reminder = false
    mail_active_pre_consumer_information = false
    mail_active_signature_reminder = false
    mail_active_signature_successful = false
    mail_active_signature_regenerated = false
    mail_active_welcome = false
    mail_active_interest_payment_announcement = false
    mail_active_interest_payment_payout_received = false
    mail_active_signatures_regenerated = false
    mail_active_voucher_received = false
    mail_active_feedback_review = false
    mail_active_wallet_balance = false
    mail_active_voucher_balance = false
    mail_encryption: string | null = null
    mail_from_name: string | null = null
    mail_from_address: string | null = null
    mail_host: string | null = null
    mail_port: number | null = null
    mail_username: string | null = null
    is_platform = false
    is_realm = false
    mail_password: string | null | undefined = null
    payment_account_id: string | null = null
    webhooks_activated = false
    clearing_systems: PlatformClearingSystem[] = []
    attachments: PlatformAttachment[] = []
    disclaimers: PlatformDisclaimer[] = []
    email_templates: PlatformEmailTemplate[] = []
    policies: PlatformPolicy[] = []
    webhooks: PlatformWebhook[] = []
    funding_total_status: number[] = []
    voucher_wallet: string | null = null
    voucherify_campaigns: VoucherCampaign[] = []
    has_credential_simply_tokenized = false
    has_credential_sproof = false
    has_credential_voucherify = false
    payment_qr_code_type: string | null = null
    esac_kyc_active = false
    esac_form_natural_person = new EsackycAttachment()
    esac_form_legal_entity = new EsackycAttachment()
    esac_form_legal_ubo = new EsackycAttachment()
    acceptance_document_active = false
    acceptance_document = new PlatformAcceptanceDocument()
    compliance_profile: PlatformComplianceProfile | null = null
    self_disclosure_form = new SelfDisclosureForm()
    kyc_provider: string | null = null
    membership_management_active = false
    membership_type_manual = false
    membership_type_sproof = false
    qualified_signature = false
    use_rule = false
    membership_number_active = false
    membership_number: string | null = null
    membership_number_prefix_natural: string | null = null
    membership_number_prefix_legal: string | null = null
    membership_document = new PlatformMembershipDocument()
    membership_document_description: string | null = null
}

export class PlatformAcceptanceDocument {
    id?: string | null = null
    name?: string | null = null
    file_name?: string | null = null
    thumb_image_url?: string | null = null
    url?: string | null = null
    upload_key?: string | null = null
    delete?: boolean | null = null
}

export class PlatformMembershipDocument {
    id?: string | null = null
    name?: string | null = null
    file_name?: string | null = null
    thumb_image_url?: string | null = null
    url?: string | null = null
    upload_key?: string | null = null
    delete?: boolean | null = null
}

export class SelfDisclosureForm {
    id?: string | null = null
    name?: string | null = null
    thumb_image_url?: string | null = null
    url?: string | null = null
    upload_key?: string | null = null
    delete?: boolean | null = null
}

export interface PlatformClearingSystem {
    id?: string | null
    clearing_system: string
}

export class PlatformAttachment {
    id?: string | null
    name: string | null = null
    type: string | null = null
    url: string | null = null
    upload_key: string | null = null
}

export class PlatformDisclaimer {
    id?: string
    platform_uid: string | null = null
    risk_title: string | null = null
    risk_content: string | null = null
    language: string | null = null
    countries_applied: string[] | null = null
    countries_not_applied: string[] | null = null
}

export class PlatformEmailTemplate {
    id?: string
    platform_uid?: string
    language: string | null = null
    subject: string | null = null
    template_name: string | null = null
    template_content: string | null = null
}

export interface PlatformEmailTemplateOption {
    name: string
    value: string
    for_platform: boolean
    for_realm: boolean
}

export class PlatformPolicy {
    id?: string
    country: string | null = null
    language: string | null = null
    platform_uid?: string
    policy_name: PlatformPolicyType | null = null
    policy_content: string | null = null
}

export class PlatformWebhook {
    id?: string
    url: string | null = null
    action: PlatformWebhookAction | null = null
    secret: string | null = null
}

export class VoucherCampaign {
    id: string | null = null
    voucherify_campaign_name: string | null = null
    type: VoucherCampaignType = VoucherCampaignType.REFERRAL_PROGRAM
    default = false
    disabled = false
    created_at: Date | null = null
}

export class EsackycAttachment {
    name?: string | null = null
    thumb_image_url?: string | null = null
    upload_key?: string | null
    delete?: boolean | null = null
}
