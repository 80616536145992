<template>
    <ContractsTable>
        <template #actions>
            <DeleteBulkButton
                v-if="userStore.can('delete_contract')"
                :selected="selected"
                @click.stop="handleDeleteContracts"
            />
            <CreateButton
                v-if="userStore.can('create_contract')"
                @click.stop="handleCreateContract"
            />
        </template>
        <template #filters>
            <DropdownFilter
                :pin="pinFilters"
                @apply="applyFilters"
                @clear="clearFilters"
                @toggle-pin="togglePinFilters"
            >
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.platform_name"
                            v-model:options="platformsSelectOptions"
                            name="platform_name"
                            class="w-full"
                            :placeholder="$t('platform')"
                            :disabled="disableFilter('platform_name')"
                            :on-search="loadPlatformsSelectOptions"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.asset_uid"
                            v-model:options="assetSelectOptions"
                            name="asset"
                            class="w-full"
                            :placeholder="$t('asset')"
                            :disabled="disableFilter('asset_uid')"
                            :on-search="loadAssetSelectOptions"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <TextField
                            v-model="filtersFields.interest_rate"
                            name="interest_rate"
                            :disabled="disableFilter('interest_rate')"
                            :placeholder="$t('interest_rate')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <ToggleSwitch
                        v-model="filtersFields.is_bullet_loan"
                        label="bullet"
                        name="bullet"
                        :disabled="disableFilter('is_bullet_loan')"
                    />
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.contract_type"
                            name="contract_type"
                            class="w-full"
                            :options="mapEnumToArray(ContractType)"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('contract_type')"
                            :disabled="disableFilter('contract_type')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.active"
                            name="active"
                            class="w-full"
                            :options="[
                                { label: $t('yes'), value: 1 },
                                { label: $t('no'), value: 0 },
                            ]"
                            :placeholder="$t('active')"
                            :disabled="disableFilter('active')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <SelectField
                            v-model="filtersFields.contract_period_type"
                            name="contract_period_type"
                            :options="mapEnumToArray(ContractPeriodType)"
                            :reduce="reduceEnumValue"
                            :placeholder="$t('period')"
                            :disabled="disableFilter('contract_period_type')"
                            @keydown.enter.prevent="handleEnterPress"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.next_payment_date"
                            placeholder="next_payment"
                            name="next_payment_date"
                            :disabled="disableFilter('next_payment_date')"
                        />
                    </label>
                </DropdownFilterItem>
                <DropdownFilterItem>
                    <label class="flex items-center">
                        <Datepicker
                            v-model="filtersFields.created_at"
                            placeholder="created_on"
                            name="created_on"
                            :disabled="disableFilter('created_at')"
                        />
                    </label>
                </DropdownFilterItem>
            </DropdownFilter>
        </template>
    </ContractsTable>

    <ModalWrapper v-if="isModalOpen" />
</template>

<script setup lang="ts">
import moment from 'moment'

import {
    ModalButtonStyles,
    useEnum,
    useModal,
    useTable,
} from '@composables/common'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PlatformNameCellRenderer from '@partials/platforms/PlatformNameCellRenderer.vue'
import {
    Datepicker,
    DropdownFilter,
    DropdownFilterItem,
    NameAndId,
    SelectField,
    TextField,
    ToggleSwitch,
} from '@src/components'
import { ContractPeriodType, ContractType } from '@src/enums'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import DeleteBulkButton from '@src/partials/actions/DeleteBulkButton.vue'
import { Asset, Contract, Platform, TableSorting } from '@src/types'
import { getAsset, getAssets } from '@src/utils/api/assets'
import {
    associateOrders as apiAssociateOrders,
    deleteContract as apiDeleteContract,
    deleteContracts as apiDeleteContracts,
    getContracts,
} from '@src/utils/api/contracts'
import { getPlatforms } from '@src/utils/api/platforms'
import { handleEnterPress } from '@src/utils/helpers'
import { useToastStore } from '@src/utils/stores/toast'
import { useUserStore } from '@src/utils/stores/user'
import { computed, h, onMounted, ref, shallowRef, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const props = withDefaults(
    defineProps<{
        initialFilters?: {
            asset_uid?: string | null
            asset_title?: string | null
            platform_name?: string | null
            interest_rate?: string | null
            is_bullet_loan?: false | null
            contract_type?: string | null
            contract_period_type?: string | null
            next_payment_date?: string | null
            created_at?: string | null
            active?: string | null
        } | null
    }>(),
    {
        initialFilters: null,
    }
)

const { initialFilters } = toRefs(props)

const router = useRouter()
const toast = useToastStore()
const i18n = useI18n()
const { ModalWrapper, openModal } = useModal()

const contracts = ref<Contract[]>([])
const contractsCount = ref<number>(0)

const { mapEnumToArray, reduceEnumValue, getEnumLabel } = useEnum()
const userStore = useUserStore()

const disableFilter = (filter: string) => {
    if (initialFilters.value) {
        return (
            Object.keys(initialFilters.value).filter((item) => {
                return item === filter
            }).length > 0
        )
    }
    return false
}

const {
    TableWrapper: ContractsTable,
    selected,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    setFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadContracts,
    filters: {
        asset_uid: null,
        asset_title: null,
        platform_name: null,
        interest_rate: null,
        is_bullet_loan: null,
        contract_type: null,
        contract_period_type: null,
        next_payment_date: null,
        created_at: null,
        active: null,
        search: null,
    },
    disableFilters: initialFilters.value
        ? Object.keys(initialFilters.value)
        : [],
    tableProps: {
        id: 'contracts-table',
        label: 'contracts',
        help: 'help_contracts_table',
        pagination: true,
        data: contracts,
        total: contractsCount,
        sorting: ref<TableSorting[]>([
            {
                field: 'created_at',
                direction: 'desc',
            },
            {
                field: 'active',
                direction: 'desc',
            },
        ]),
        columns: [
            {
                label: 'id',
                key: 'contract_uid',
                select: true,
            },
            {
                label: 'id',
                key: 'contract_uid',
                url: contractsTableUrl,
                sorting: true,
                condition: userStore.can('see_id_columns'),
                copy: true,
            },
            {
                label: 'asset',
                key: 'asset_title',
                url: assetsTableUrl,
                sorting: true,
                copy: true,
            },
            {
                label: 'platform',
                key: 'platform_name',
                sorting: true,
                cellRenderer: shallowRef(PlatformNameCellRenderer),
            },
            {
                label: 'interest',
                key: 'interest_rate',
                sorting: true,
            },
            {
                label: 'bullet',
                key: 'is_bullet_loan',
                sorting: true,
                cellRenderer: (props: { rowData: Contract }) => {
                    if (props.rowData.is_bullet_loan) {
                        return h(FontAwesomeIcon, {
                            icon: 'fa-solid fa-check',
                        })
                    }
                },
            },
            {
                label: 'type',
                key: 'contract_type',
                sorting: true,
                cellRenderer: (props: { rowData: Contract }) => {
                    return getEnumLabel(
                        ContractType,
                        props.rowData.contract_type
                    )
                },
            },
            {
                label: 'period',
                key: 'contract_period_type',
                sorting: true,
                cellRenderer: (props: { rowData: Contract }) => {
                    return getEnumLabel(
                        ContractPeriodType,
                        props.rowData.contract_period_type
                    )
                },
            },
            {
                label: 'tax_calculation',
                key: 'tax_calculation_enabled',
                sorting: true,
                cellRenderer: (props: { rowData: Contract }) => {
                    if (props.rowData.tax_calculation_enabled) {
                        return h(FontAwesomeIcon, {
                            icon: 'fa-circle-check',
                            class: 'text-primary-accent-500',
                        })
                    } else {
                        return h(FontAwesomeIcon, {
                            icon: 'fa-circle-xmark',
                            class: 'text-danger-500',
                        })
                    }
                },
            },
            {
                label: 'has_orders',
                key: 'orders_count',
                sorting: true,
                cellRenderer: (props: { rowData: Contract }) => {
                    if (props.rowData.orders_count) {
                        return h(FontAwesomeIcon, {
                            icon: 'fa-circle-check',
                            class: 'text-primary-accent-500',
                        })
                    } else {
                        return h(FontAwesomeIcon, {
                            icon: 'fa-circle-xmark',
                            class: 'text-danger-500',
                        })
                    }
                },
            },
            {
                label: 'orders',
                key: 'orders_count',
                sorting: true,
            },
            {
                label: 'payouts',
                key: 'interest_payments_count',
                sorting: true,
            },
            {
                label: 'next_payment',
                key: 'next_payment_date',
                sorting: true,
                cellRenderer: (props: { rowData: Contract }) => {
                    return moment(props.rowData.next_payment_date).format(
                        'DD.MM.yyyy'
                    )
                },
            },
            {
                label: 'created_on',
                key: 'created_at',
                sorting: true,
                cellRenderer: (props: { rowData: Contract }) => {
                    return moment(props.rowData.created_at).format('DD.MM.yyyy')
                },
            },
            {
                label: 'active',
                key: 'active',
                sorting: true,
                cellRenderer: (props: { rowData: Contract }) => {
                    if (props.rowData.active) {
                        return h(FontAwesomeIcon, {
                            icon: 'fa-circle-check',
                            class: 'text-primary-accent-500',
                        })
                    } else {
                        return h(FontAwesomeIcon, {
                            icon: 'fa-circle-xmark',
                            class: 'text-danger-500',
                        })
                    }
                },
            },
        ],
        actions: [
            {
                action: handleViewContract,
                icon: 'fa-eye',
                title: 'view',
                condition: userStore.can('view_contracts'),
            },
            {
                action: handleAssociateOrders,
                icon: 'fa-sack-dollar',
                title: 'associate_orders',
                condition: userStore.can('associate_orders'),
            },
            {
                action: handleEditContract,
                icon: 'fa-solid fa-pen',
                title: 'edit',
                condition: userStore.can('update_contract'),
            },
            {
                action: handleDuplicateContract,
                icon: 'fa-copy',
                title: 'duplicate',
                condition: (contract: Contract) => {
                    return (
                        userStore.can('update_contract') &&
                        contract.contract_type === ContractType.CASH &&
                        contract.active
                    )
                },
            },
            {
                action: handleDeleteContract,
                icon: 'fas fa-trash-alt',
                title: 'delete',
                color: 'text-danger-500',
                condition: userStore.can('delete_contract'),
            },
        ],
    },
})

const isModalOpen = ref(false)

const selectedContracts = computed(() => {
    return contracts.value.filter(
        (item: Contract) =>
            item.contract_uid && selected.value.includes(item.contract_uid)
    )
})

const assetSelectOptions = ref<{ label: string; value: string | null }[]>([])
const platformsSelectOptions = ref<string[]>([])

async function loadAssetSelectOptionsByUID(uid: string) {
    const assetRequest = await getAsset(uid)
    const asset = assetRequest.data.asset
    assetSelectOptions.value = [
        {
            label: asset.asset_title as string,
            value: asset.asset_uid as string,
        },
    ]
}

async function loadAssetSelectOptions(search: string) {
    const assetsRequest = await getAssets({
        'filter[asset_title]': search,
    })
    assetSelectOptions.value = assetsRequest.data.assets.map((asset: Asset) => {
        return {
            label: asset.asset_title as string,
            value: asset.asset_uid as string,
        }
    })
}

async function loadPlatformsSelectOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
    })
    platformsSelectOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

function contractsTableUrl(contract: Contract) {
    return '/contracts/' + contract.contract_uid
}

function assetsTableUrl(contract: Contract) {
    return '/assets/' + contract.asset_uid
}

function handleViewContract(contract: Contract) {
    router.push({
        name: 'contract',
        params: { id: contract.contract_uid },
    })
}

function handleEditContract(contract: Contract) {
    router.push({
        name: 'contract.edit',
        params: { id: contract.contract_uid },
    })
}

async function handleAssociateOrders(contract: Contract) {
    const assetRequest = await getAsset(contract.asset_uid as string)
    const asset = assetRequest.data.asset

    isModalOpen.value = true

    openModal(
        {
            id: 'associate-orders-modal',
            title: 'modal_title_associate_orders',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: async () => await associateOrders(contract, asset),
        },
        () =>
            h(
                'p',
                i18n.t('help_associate_orders_modal_text', {
                    asset: asset.asset_title,
                    contract: contract.contract_uid,
                })
            ),
        {
            submitButtonText: 'associate_orders',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
        }
    )
}

function handleDuplicateContract(contract: Contract) {
    isModalOpen.value = true

    openModal(
        {
            id: 'associate-orders-modal',
            title: 'modal_title_duplicate_cash_contract',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => duplicateContract(contract),
        },
        () => h('p', i18n.t('modal_text_duplicate_cash_contract')),
        {
            submitButtonText: 'duplicate',
            styles: {
                submitButton: ModalButtonStyles['BLUE'],
            },
        }
    )
}

function handleDeleteContract(contract: Contract) {
    isModalOpen.value = true
    openModal(
        {
            id: 'delete_contract-modal',
            title: 'modal_title_delete_contract',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: async () => await deleteContract(contract),
        },
        () =>
            h(NameAndId, {
                idKey: 'contract_uid',
                nameKey: 'asset_title',
                modelValue: [contract],
            }),
        {
            submitButtonText: 'delete',
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

function handleDeleteContracts() {
    isModalOpen.value = true
    openModal(
        {
            id: 'delete_contracts',
            title: 'modal_title_delete_contracts',
            open: isModalOpen,
            onCancel: () => (isModalOpen.value = false),
            onSubmit: () => deleteContracts(),
        },
        () =>
            h(NameAndId, {
                idKey: 'contract_uid',
                nameKey: 'asset_title',
                modelValue: selectedContracts.value,
            }),
        {
            submitButtonText: 'delete',
            styles: {
                submitButton: ModalButtonStyles['RED'],
            },
        }
    )
}

async function associateOrders(contract: Contract, asset: Asset) {
    const associateOrdersResponse = await apiAssociateOrders(contract, asset)
    isModalOpen.value = false
    toast.success(
        i18n.t('toast_success_associate_orders', {
            asset: asset.asset_title,
            contract: contract.contract_uid,
            orders: associateOrdersResponse.data.updated_orders_count,
        })
    )
}

function duplicateContract(contract: Contract) {
    isModalOpen.value = false
    router.push({
        name: 'contract.copy',
        params: { copyUid: contract.contract_uid },
    })
}

async function deleteContract(contract: Contract) {
    await apiDeleteContract(contract)
    toast.success(
        i18n.t('toast_success_contract_deleted', {
            contract: contract.contract_uid,
        })
    )
    isModalOpen.value = false
    await loadContracts()
}

async function deleteContracts() {
    await apiDeleteContracts(selected.value)
    toast.success(
        i18n.t('toast_success_contracts_deleted', {
            contracts: selected.value.join(', '),
        })
    )
    isModalOpen.value = false
    selected.value = []
    await loadContracts()
}

async function loadContracts() {
    const { data } = await getContracts(
        filters.value,
        page.value,
        limit.value,
        sorting.value
    )
    contracts.value = data.contracts
    contractsCount.value = data.contracts_count
    selected.value = []
}

function handleCreateContract() {
    router.push({
        name: 'contract.create',
    })
}

onMounted(async () => {
    if (initialFilters.value) {
        filtersFields.value = {
            ...filtersFields.value,
            ...initialFilters.value,
        }
        if (filtersFields.value.asset_uid) {
            await loadAssetSelectOptionsByUID(
                filtersFields.value.asset_uid as string
            )
        }
        if (filtersFields.value.asset_title) {
            await loadAssetSelectOptions(
                filtersFields.value.asset_title as string
            )
        }
        setFilters()
    }
    await loadContracts()
})
</script>

<style lang="scss" scoped>
input {
    &:disabled {
        @apply disabled:bg-gray-100;
    }
}
</style>
