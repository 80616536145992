<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-store" />&nbsp;{{ $t('platforms') }}
        </template>

        <PlatformsTable>
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('delete_platform')"
                    :selected="selected"
                    @click.stop="openBulkDeletePlatformsModal"
                />

                <CreateButton
                    v-if="userStore.can('create_platform')"
                    @click.stop="openCreatePlatformModal"
                />
            </template>
            <template #filters>
                <DropdownFilter
                    :pin="pinFilters"
                    @apply="applyFilters"
                    @clear="clearFilters"
                    @toggle-pin="togglePinFilters"
                >
                    <DropdownFilterItem>
                        <label class="flex items-center">
                            <TextField
                                v-model="filtersFields.platform_name"
                                name="platform_name"
                                :placeholder="$t('platform_name')"
                            />
                        </label>
                    </DropdownFilterItem>
                    <DropdownFilterItem>
                        <label class="flex items-center">
                            <SelectField
                                v-model="filtersFields.parent_platform_name"
                                v-model:options="filterParentPlatformOptions"
                                name="parent_platform_name"
                                class="w-full"
                                :placeholder="$t('parent_platform_name')"
                                :on-search="loadFilterParentPlatformOptions"
                            />
                        </label>
                    </DropdownFilterItem>
                </DropdownFilter>
            </template>
        </PlatformsTable>

        <template #modals>
            <CreatePlatformModal
                ref="createPlatformModal"
                v-model="tmpPlatform"
                v-model:open="createPlatformModalOpen"
                @submit="createPlatform"
                @cancel="closeCreatePlatformModal"
            />
            <DeleteItemModal
                id="delete-platform-modal"
                ref="deletePlatformModal"
                v-model="tmpPlatform"
                v-model:open="deletePlatformModalOpen"
                title="modal_title_delete_platform"
                name-key="platform_name"
                id-key="platform_uid"
                @submit="deletePlatform"
                @cancel="closeDeletePlatformModal"
            />
            <DeleteItemsModal
                id="delete-platforms-modal"
                ref="deletePlatformsModal"
                v-model="platformsToDelete"
                v-model:open="deletePlatformsModalOpen"
                title="modal_title_delete_platforms"
                id-key="platform_uid"
                name-key="platform_name"
                @submit="bulkDeletePlatforms"
                @cancel="closeDeletePlatformsModal"
            />
        </template>
    </PageWrapper>
</template>
<script setup lang="ts">
import { useTable } from '@composables/common'
import PlatformNameCellRenderer from '@partials/platforms/PlatformNameCellRenderer.vue'
import {
    DeleteItemModal,
    DeleteItemsModal,
    DropdownFilter,
    DropdownFilterItem,
    SelectField,
    TextField,
} from '@src/components'
import CreateButton from '@src/partials/actions/CreateButton.vue'
import DeleteBulkButton from '@src/partials/actions/DeleteBulkButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import CreatePlatformModal from '@src/partials/platforms/CreatePlatformModal.vue'
import { Platform, TableSorting } from '@src/types'
import {
    createPlatform as apiCreatePlatform,
    deletePlatform as apiDeletePlatform,
    deletePlatforms,
    getPlatforms,
} from '@src/utils/api/platforms'
import { useUserStore } from '@src/utils/stores/user'
import { useToastStore } from '@stores/toast'
import moment from 'moment'
import { computed, onMounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const router = useRouter()
const toast = useToastStore()
const i18n = useI18n()

const platforms = ref<Platform[]>([])
const platformsCount = ref<number>(0)

const createPlatformModal = ref(null)
const createPlatformModalOpen = ref(false)
const deletePlatformModal = ref(null)
const deletePlatformModalOpen = ref(false)
const tmpPlatform = ref<Platform>(new Platform())
const deletePlatformsModal = ref(null)
const deletePlatformsModalOpen = ref(false)

const userStore = useUserStore()

const {
    TableWrapper: PlatformsTable,
    selected,
    limit,
    sorting,
    page,
    filtersFields,
    filters,
    pinFilters,
    togglePinFilters,
    applyFilters,
    clearFilters,
} = useTable({
    loadData: loadPlatforms,
    filters: {
        platform_name: null,
        parent_platform_name: null,
        search: null,
    },
    tableProps: {
        id: 'platforms-table',
        label: 'platforms',
        help: 'help_platforms_table',
        pagination: true,
        data: platforms,
        total: platformsCount,
        sorting: ref<TableSorting[]>([
            {
                field: 'created_on',
                direction: 'desc',
            },
        ]),
        columns: [
            {
                label: 'id',
                key: 'platform_uid',
                select: true,
            },
            {
                label: 'id',
                key: 'platform_uid',
                url: platformsTableUrl,
                sorting: true,
                condition: userStore.can('see_id_columns'),
            },
            {
                label: 'name',
                key: 'platform_name',
                cellRenderer: shallowRef(PlatformNameCellRenderer),
                sorting: true,
            },
            {
                label: 'created_on',
                key: 'created_on',
                sorting: true,
                cellRenderer: (props: { rowData: Platform }) => {
                    return moment(props.rowData.created_on).format('DD.MM.yyyy')
                },
            },
        ],
        actions: [
            {
                action: handleViewPlatform,
                icon: 'fa-eye',
                title: 'view',
                condition: userStore.can('view_platforms'),
            },
            {
                action: handleEditPlatform,
                icon: 'fa-solid fa-pen',
                title: 'edit',
                condition: userStore.can('update_platform'),
            },
            {
                action: handleDeletePlatform,
                icon: 'fas fa-trash-alt',
                title: 'delete',
                color: 'text-danger-500',
                condition: userStore.can('delete_platform'),
            },
        ],
    },
})

function platformsTableUrl(platform: Platform) {
    return '/platforms/' + platform.platform_name
}

function handleViewPlatform(platform: Platform) {
    router.push({
        name: 'platform',
        params: { slug: platform.platform_name },
    })
}

function handleEditPlatform(platform: Platform) {
    router.push({
        name: 'platform.edit',
        params: { slug: platform.platform_name },
    })
}

const platformsToDelete = computed(() => {
    return platforms.value.filter(
        (platform) =>
            platform.platform_uid &&
            selected.value.includes(platform.platform_uid)
    )
})

onMounted(async () => {
    await loadPlatforms()
})

const filterParentPlatformOptions = ref<string[]>([])

function handleDeletePlatform(platform: Platform) {
    tmpPlatform.value = platform
    deletePlatformModalOpen.value = true
}

function closeDeletePlatformModal() {
    tmpPlatform.value = new Platform()
}

async function deletePlatform(platform: Platform) {
    if (!platform.platform_name) {
        toast.error(i18n.t('toast_error_cannot_delete_platform'))
        return
    }

    await apiDeletePlatform(platform.platform_name)

    deletePlatformModalOpen.value = false

    tmpPlatform.value = new Platform()

    toast.success(
        i18n.t('toast_success_platform_deleted', {
            platform: platform.platform_name,
        })
    )

    page.value = 1
    await loadPlatforms()
}

function openBulkDeletePlatformsModal() {
    deletePlatformsModalOpen.value = true
}

function closeDeletePlatformsModal() {
    deletePlatformsModalOpen.value = false
}

async function bulkDeletePlatforms() {
    await deletePlatforms(selected.value)

    toast.success(
        i18n.t('toast_success_platforms_deleted', {
            platforms: selected.value.join(', '),
        })
    )

    deletePlatformsModalOpen.value = false

    selected.value = []

    loadPlatforms()
}

function openCreatePlatformModal(): void {
    createPlatformModalOpen.value = true
}

function closeCreatePlatformModal() {
    tmpPlatform.value = new Platform()
    createPlatformModalOpen.value = false
}

async function createPlatform(next?: string) {
    await apiCreatePlatform(tmpPlatform.value)

    createPlatformModalOpen.value = false

    if (next && next == 'edit') {
        router.push({
            name: 'platform.edit',
            params: { slug: tmpPlatform.value.platform_name },
        })
    } else if (next && next == 'view') {
        router.push({
            name: 'platform',
            params: { slug: tmpPlatform.value.platform_name },
        })
    } else {
        page.value = 1
        limit.value = parseInt(import.meta.env.VITE_DEFAULT_TABLE_LIMIT)
        await loadPlatforms()
    }

    // clear new platform object
    tmpPlatform.value = new Platform()

    toast.success(i18n.t('toast_success_platform_created'))
}

async function loadFilterParentPlatformOptions(search: string) {
    const parentPlatformRequest = await getPlatforms({
        'filter[platform_name]': search,
        'filter[is_parent]': true,
    })
    filterParentPlatformOptions.value =
        parentPlatformRequest.data.platforms.map((platform: Platform) => {
            return platform.platform_name as string
        })
}

async function loadPlatforms() {
    const platformsRequest = await getPlatforms(
        filters.value,
        page.value,
        limit.value,
        sorting.value
    )
    platforms.value = platformsRequest.data.platforms
    platformsCount.value = platformsRequest.data.platforms_count
    selected.value = []
}
</script>
