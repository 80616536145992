import { api, ApiRes } from '@src/utils/api/client'
import { Platform, PlatformEmailTemplateOption, TableSorting } from '@src/types'
import { AxiosRequestConfig } from 'axios'

export interface PlatformsResponse {
    success: boolean
    platforms: Platform[]
    platforms_count: number
}

export interface PlatformResponse {
    success: string
    platform: Platform
}

export interface PlatformEmailTemplateListResponse {
    email_templates: PlatformEmailTemplateOption[]
}

export const getPlatforms = (
    filters?: object,
    page?: number,
    limit?: number,
    sorting?: TableSorting[]
): ApiRes<PlatformsResponse> => {
    const config: AxiosRequestConfig = {}

    config.params = {}

    if (filters && Object.keys(filters).length) {
        config.params = filters
    }

    if (typeof page !== 'undefined' && typeof limit !== 'undefined') {
        config.params['range'] = page * limit - limit + '-' + page * limit
    }

    if (sorting && sorting.length) {
        config.params['sort'] = sorting
            .map((sort) => {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            })
            .join(',')
    }

    return api.get('v2/manage/platforms', config)
}

export const getPlatform = (slug: string): ApiRes<PlatformResponse> =>
    api.get('v2/manage/platforms/' + slug)

export const createPlatform = (platform: Platform): ApiRes<PlatformResponse> =>
    api.post('v2/manage/platforms', platform)

export const updatePlatform = (platform: Platform): ApiRes<PlatformResponse> =>
    api.patch('v2/manage/platforms/' + platform.platform_name, platform)

export const deletePlatforms = (platforms: string[]): ApiRes<string> =>
    api.delete('v2/manage/platforms', { data: { platforms: platforms } })

export const deletePlatform = (slug: string): ApiRes<string> =>
    api.delete('manage/platforms/' + slug)

export const getEmailTemplatesList =
    (): ApiRes<PlatformEmailTemplateListResponse> =>
        api.get('v2/manage/platforms/email-templates')

export const sendEmailTemplateTest = (
    slug: string,
    template: string
): ApiRes<[]> =>
    api.post(
        'manage/platforms/' + slug + '/mail-templates/' + template + '/send'
    )
