<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-store" />
            {{ $t('platform') }}:
            <PlatformTitle
                v-if="platform"
                :platform="platform"
            />
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/platforms'"
                icon="fa-list"
                title="platforms"
                @click="toPlatforms"
            />
            <EditButton
                v-if="userStore.can('update_platform')"
                @click="editPlatform"
            />
            <DeleteButton
                v-if="userStore.can('delete_platform')"
                @click="openDeletePlatformModal"
            />
        </template>

        <!-- SUMMARY -->
        <PlatformSummary v-if="platform.platform_uid" />
        <PlatformEmailTemplatesTable v-if="platform.platform_uid">
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('update_platform')"
                    v-model:selected="selectedPlatformEmailTemplatesIds"
                    @click.stop="() => handleDeletePlatformEmailTemplates()"
                />
                <CreateButton
                    v-if="userStore.can('update_platform')"
                    aria-controls="platform-email-templates-modals"
                    @click.stop="
                        () => handleEditOrCreatePlatformEmailTemplate()
                    "
                />
            </template>
        </PlatformEmailTemplatesTable>
        <PlatformDisclaimersTable v-if="platform.platform_uid">
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('update_platform')"
                    v-model:selected="selectedPlatformDisclaimersIds"
                    @click.stop="() => handleDeletePlatformDisclaimers()"
                />
                <CreateButton
                    v-if="userStore.can('update_platform')"
                    aria-controls="platform-disclaimers-modals"
                    @click.stop="() => handleEditOrCreatePlatformDisclaimer()"
                />
            </template>
        </PlatformDisclaimersTable>
        <PlatformPoliciesTable v-if="platform.platform_uid">
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('update_platform')"
                    v-model:selected="selectedPlatformPoliciesIds"
                    @click.stop="() => handleDeletePlatformPolicies()"
                />
                <CreateButton
                    v-if="userStore.can('update_platform')"
                    aria-controls="platform-policy-modals"
                    @click.stop="() => handleEditOrCreatePlatformPolicy()"
                />
            </template>
        </PlatformPoliciesTable>
        <PlatformAttachmentsTable v-if="platform.platform_uid">
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('update_platform')"
                    v-model:selected="selectedPlatformAttachmentsIds"
                    @click.stop="() => handleDeletePlatformAttachments()"
                />
                <CreateButton
                    v-if="userStore.can('update_platform')"
                    aria-controls="platform-attachment-modals"
                    @click.stop="() => handleUpdateOrCreatePlatformAttachment()"
                />
            </template>
        </PlatformAttachmentsTable>
        <PlatformWebhooksTable v-if="platform.platform_uid">
            <template #actions>
                <DeleteBulkButton
                    v-if="userStore.can('update_platform')"
                    v-model:selected="selectedPlatformWebhooksIds"
                    @click.stop="() => handleDeletePlatformWebhooks()"
                />
                <CreateButton
                    v-if="userStore.can('update_platform')"
                    aria-controls="platform-webhooks-modals"
                    @click.stop="() => handleEditOrCreatePlatformWebhook()"
                />
            </template>
        </PlatformWebhooksTable>
        <AssetsTable
            v-if="platform.platform_name"
            :initial-filters="{
                platform_name_exact: platform.platform_name,
            }"
            :create-initial-values="assetTableCreateInitialValues"
            :create-disabled-fields="['platform_name']"
        />
        <CampaignsTable
            v-if="platform.platform_name"
            :initial-filters="{
                platform_name_exact: platform.platform_name,
            }"
            :create-initial-values="campaignsTableCreateInitialValues"
            :create-disabled-fields="['platform_name']"
        />
        <OrdersTable
            v-if="platform.platform_name"
            :initial-filters="{
                platform_name_exact: platform.platform_name,
            }"
        />
        <template #modals>
            <DeleteItemModal
                id="delete-platform-modal"
                v-model="platform"
                v-model:open="deletePlatformModalOpen"
                title="modal_title_delete_platform"
                name-key="platform_name"
                id-key="platform_uid"
                @submit="deletePlatform"
            />
        </template>
        <ModalWrapper v-if="isModalOpen" />
    </PageWrapper>
</template>
<script setup lang="ts">
import { useModal } from '@composables/common'
import { usePlatformAttachmentsTable } from '@composables/platforms/usePlatformAttachmentsTable'
import { usePlatformDisclaimersTable } from '@composables/platforms/usePlatformDisclaimersTable'
import { usePlatformEmailTemplatesTable } from '@composables/platforms/usePlatformEmailTemplatesTable'
import { usePlatformPoliciesTable } from '@composables/platforms/usePlatformPoliciesTable'
import { usePlatformWebhooksTable } from '@composables/platforms/usePlatformWebhooksTable'
import BackButton from '@partials/actions/BackButton.vue'
import CreateButton from '@partials/actions/CreateButton.vue'
import DeleteBulkButton from '@partials/actions/DeleteBulkButton.vue'
import DeleteButton from '@partials/actions/DeleteButton.vue'
import EditButton from '@partials/actions/EditButton.vue'
import AssetsTable from '@partials/assets/AssetsTable.vue'
import CampaignsTable from '@partials/campaigns/CampaignsTable.vue'
import OrdersTable from '@partials/orders/OrdersTable.vue'
import PageWrapper from '@partials/pages/PageWrapper.vue'
import PlatformTitle from '@partials/platforms/PlatformTitle.vue'
import { DeleteItemModal } from '@src/components'
import { useEnum, useSummary } from '@src/composables/common'
import { QRCodeType } from '@src/enums'
import {
    FundingTotalStatus,
    PlatformComplianceProfile,
} from '@src/enums/Platform'
import {
    Asset,
    Campaign,
    Platform,
    PlatformAttachment,
    PlatformDisclaimer,
    PlatformEmailTemplate,
    PlatformPolicy,
    PlatformWebhook,
} from '@src/types'
import { SummaryItem } from '@src/types/Summary'
import {
    deletePlatform as apiDeletePlatform,
    getPlatform,
    updatePlatform,
} from '@src/utils/api/platforms'
import { useUserStore } from '@src/utils/stores/user'
import { useToastStore } from '@stores/toast'
import moment from 'moment'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const userStore = useUserStore()
const { getEnumLabel } = useEnum()

const platform = ref<Platform>(new Platform())
const deletePlatformModalOpen = ref(false)

const platformAttachments = ref<PlatformAttachment[]>([])
const platformWebhooks = ref<PlatformWebhook[]>([])
const platformPolicies = ref<PlatformPolicy[]>([])
const platformEmailTemplates = ref<PlatformEmailTemplate[]>([])
const platformDisclaimers = ref<PlatformDisclaimer[]>([])

const { ModalWrapper, openModal } = useModal()

const isModalOpen = ref(false)

const platformDetailsItems = computed<SummaryItem[]>(() => {
    return [
        {
            label: i18n.t('id'),
            value: platform.value.platform_uid,
            copy: true,
        },
        {
            label: i18n.t('platform_name'),
            value: platform.value.platform_name,
            copy: true,
        },
        {
            label: i18n.t('parent_platform_name'),
            value: platform.value.parent_platform_name,
            condition: !!platform.value.parent_platform_name,
            special: 'link',
            linkURL: `/platforms/${platform.value.parent_platform_name}`,
            copy: true,
        },
        {
            label: i18n.t('clearing_systems'),
            value: platform.value.clearing_systems.length
                ? platform.value.clearing_systems
                      .map((clearing_system) => {
                          return clearing_system.clearing_system
                      })
                      .join(', ')
                : '-',
        },
        {
            label: i18n.t('url'),
            value: platform.value.platform_url,
            special: 'link',
            icon: 'fa-chain',
            copy: true,
        },
        {
            label: i18n.t('terms'),
            value: platform.value.terms_url,
            special: 'link',
            icon: 'fa-chain',
            copy: true,
        },
        {
            label: i18n.t('privacy_policy'),
            value: platform.value.privacy_url,
            special: 'link',
            icon: 'fa-chain',
            copy: true,
        },
        {
            label: i18n.t('qr_code_type'),
            value: getEnumLabel(
                QRCodeType,
                platform.value.payment_qr_code_type
            ),
        },
        {
            label: i18n.t('is_realm'),
            value: platform.value.is_realm,
            special: 'check',
            condition: userStore.is('developer'),
        },
        {
            label: i18n.t('is_platform'),
            value: platform.value.is_platform,
            special: 'check',
            condition: userStore.is('developer'),
        },
        {
            label: i18n.t('platform_logo'),
            value: platform.value.platform_logo?.url ?? null, // Ensure it falls back to null if undefined
            special: 'image',
        },
        {
            label: i18n.t('created_by'),
            value: platform.value.created_by,
            copy: true,
            condition: platform.value.created_by !== null,
        },
        {
            label: i18n.t('payment_account_id'),
            value: platform.value.payment_account_id,
            condition: platform.value.payment_account_id !== null,
        },
        {
            label: i18n.t('funding_total_status'),
            value: platform.value.funding_total_status
                .map((item) => i18n.t(FundingTotalStatus[item]))
                .join(', '),
        },
        {
            label: i18n.t('webhooks_activated'),
            value: platform.value.webhooks_activated,
            special: 'check',
        },
        {
            label: i18n.t('voucher_wallet'),
            value: platform.value.voucher_wallet,
            condition: platform.value.voucher_wallet !== null,
        },
        {
            label: i18n.t('has_credential_simply_tokenized'),
            value: platform.value.has_credential_simply_tokenized,
            special: 'check',
        },
        {
            label: i18n.t('has_credential_sproof'),
            value: platform.value.has_credential_sproof,
            special: 'check',
        },
        {
            label: i18n.t('has_credential_voucherify'),
            value: platform.value.has_credential_voucherify,
            special: 'check',
        },
        {
            label: i18n.t('esac_kyc_active'),
            value: platform.value.esac_kyc_active,
            special: 'check',
            condition: userStore.can('view_esac_kyc_active'),
        },
        {
            label: i18n.t('acceptance_document_active'),
            value: platform.value.acceptance_document_active,
            special: 'check',
        },
        {
            label: i18n.t('acceptance_document'),
            value: platform.value.acceptance_document.url,
            special: 'link',
            icon: 'fa-chain',
            copy: true,
        },
        {
            label: i18n.t('compliance_profile'),
            value: getEnumLabel(
                PlatformComplianceProfile,
                platform.value.compliance_profile
            ),
            condition: platform.value.compliance_profile !== null,
        },
        {
            label: i18n.t('created_at'),
            value: moment(platform.value.created_on).format('DD.MM.yyyy HH:mm'),
        },
        {
            label: i18n.t('updated_at'),
            value: moment(platform.value.updated_at).format('DD.MM.yyyy HH:mm'),
        },
    ]
})

const assetTableCreateInitialValues = computed(() => {
    return {
        ...new Asset(),
        ...{
            platform_name: platform.value.platform_name,
        },
    }
})

const campaignsTableCreateInitialValues = computed(() => {
    return {
        ...new Campaign(),
        ...{
            platform_name: platform.value.platform_name,
        },
    }
})

const { Summary: PlatformSummary } = useSummary(platformDetailsItems)

const {
    PlatformAttachmentsTable,
    handleUpdateOrCreatePlatformAttachment,
    selectedPlatformAttachmentsIds,
    handleDeletePlatformAttachments,
} = usePlatformAttachmentsTable(
    platformAttachments,
    update,
    openModal,
    isModalOpen
)

const {
    PlatformWebhooksTable,
    selectedPlatformWebhooksIds,
    handleEditOrCreatePlatformWebhook,
    handleDeletePlatformWebhooks,
} = usePlatformWebhooksTable(platformWebhooks, update, openModal, isModalOpen)

const {
    PlatformPoliciesTable,
    selectedPlatformPoliciesIds,
    handleDeletePlatformPolicies,
    handleEditOrCreatePlatformPolicy,
} = usePlatformPoliciesTable(platformPolicies, update, openModal, isModalOpen)

const {
    PlatformEmailTemplatesTable,
    selectedPlatformEmailTemplatesIds,
    handleEditOrCreatePlatformEmailTemplate,
    handleDeletePlatformEmailTemplates,
} = usePlatformEmailTemplatesTable(
    platformEmailTemplates,
    platform,
    update,
    openModal,
    isModalOpen
)

const {
    PlatformDisclaimersTable,
    selectedPlatformDisclaimersIds,
    handleEditOrCreatePlatformDisclaimer,
    handleDeletePlatformDisclaimers,
} = usePlatformDisclaimersTable(
    platformDisclaimers,
    update,
    openModal,
    isModalOpen
)

function openDeletePlatformModal() {
    deletePlatformModalOpen.value = true
}

async function deletePlatform() {
    if (!platform.value?.platform_name) return
    await apiDeletePlatform(platform.value?.platform_name)
    toast.success(
        i18n.t('toast_success_platform_deleted', {
            platform: platform.value?.platform_name,
        })
    )
    router.push({ name: 'platforms' })
}

function editPlatform() {
    router.push({
        name: 'platform.edit',
        params: { slug: platform.value?.platform_name },
    })
}

function toPlatforms() {
    router.push({ name: 'platforms' })
}

async function load() {
    const slug = route.params.slug as string
    const platformRequest = await getPlatform(slug)
    platform.value = platformRequest.data.platform
    platformAttachments.value = platform.value.attachments
    platformWebhooks.value = platform.value.webhooks
    platformPolicies.value = platform.value.policies
    platformEmailTemplates.value = platform.value.email_templates
    platformDisclaimers.value = platform.value.disclaimers
}

async function update(successMessage: string | null = null) {
    await updatePlatform(platform.value)
    if (successMessage) {
        toast.success(successMessage)
    }
    await load()
}

watch(
    () => route.params.slug,
    async () => {
        await load()
    }
)

onMounted(async () => {
    await load()
})
</script>
<style scoped lang="sass">
.details
    @apply flex flex-col gap-4 grow
    .detail
        .label
            @apply font-bold mr-2
            &:after
                content: ':'
        .value
            a
                @apply text-blue-500
</style>
