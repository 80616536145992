<template>
    <PageWrapper>
        <template #headline>
            <fa icon="fa-store" />
            {{ platform.platform_name }}
        </template>

        <template #actions>
            <BackButton
                v-if="$router.options.history.state.back"
                @click="router.go(-1)"
            />
            <BackButton
                v-if="
                    platform.platform_name &&
                    decodeURIComponent(
                        $router.options.history.state.back?.toString() as string
                    ) !== `/platforms/${platform.platform_name}`
                "
                icon="fa-eye"
                title="platform"
                @click="goToDetails"
            />
            <BackButton
                v-if="$router.options.history.state.back !== '/platforms'"
                icon="fa-list"
                title="platforms"
                @click="toPlatforms"
            />
            <SaveButton @click="savePlatform" />
        </template>

        <AccordionBasic
            title="platform_details"
            class="mb-2"
            :open="true"
        >
            <TextField
                v-model="platform.platform_name"
                name="platform_name"
                label="platform_name"
                help="help_platform_name"
                :required="true"
            />
            <SelectField
                v-model="platform.parent_platform_name"
                v-model:options="parentPlatformOptions"
                name="parent_platform_name"
                label="parent_platform_name"
                help="help_parent_platform_name"
                :on-search="loadParentPlatformOptions"
            />
            <TextField
                v-model="platform.platform_url"
                name="url"
                label="url"
                help="help_platform_url"
                :required="true"
            />
            <ImageUpload
                v-model="platform.platform_logo"
                name="platform_logo"
                label="platform_logo"
                help="help_platform_logo"
                type="platform"
            />
        </AccordionBasic>
        <AccordionBasic
            title="links"
            class="mb-2"
        >
            <TextField
                v-model="platform.terms_url"
                name="terms_url"
                label="terms"
                help="help_platform_terms"
                :required="true"
            />
            <TextField
                v-model="platform.privacy_url"
                name="privacy_url"
                label="privacy_url"
                help="help_platform_privacy_url"
                :required="true"
            />
        </AccordionBasic>
        <AccordionBasic
            title="email_settings"
            class="mb-2"
        >
            <TextField
                v-model="platform.admin_email"
                name="admin_email"
                label="admin_email"
                help="help_platform_admin_email"
            />
            <SelectField
                v-model="platform.mail_template"
                name="mail_template"
                label="mail_template"
                help="help_mail_template"
                :options="[
                    {
                        label: $t('mail_type_standard'),
                        value: 'standard',
                    },
                ]"
            />
            <ColorPickerField
                v-if="platform.mail_template === 'standard'"
                v-model="platform.mail_template_color"
                name="mail_template_color"
                label="mail_template_color"
                help="help_mail_template_color"
            />
            <ColorPickerField
                v-if="platform.mail_template === 'standard'"
                v-model="platform.mail_template_action_button_color"
                name="mail_template_action_button_color"
                label="mail_template_action_button_color"
                help="help_mail_template_action_button_color"
            />
            <ColorPickerField
                v-if="platform.mail_template === 'standard'"
                v-model="platform.mail_template_action_button_text_color"
                name="mail_template_action_button_text_color"
                label="mail_template_action_button_text_color"
                help="help_mail_template_action_button_text_color"
            />
        </AccordionBasic>
        <AccordionBasic
            title="clearing_systems"
            class="mb-2"
        >
            <h3 class="mb-1 block text-sm font-medium">
                {{ $t('clearing_systems') }}
                <span class="text-danger-500">*</span>
            </h3>
            <ToggleSwitch
                v-model="clearingSystemCi"
                name="ci"
                label="clearing_system_ci"
            />
            <ToggleSwitch
                v-model="clearingSystemCf"
                name="cf"
                label="clearing_system_cf"
            />
            <ToggleSwitch
                v-model="clearingSystemEmission"
                name="emission"
                label="clearing_system_emission"
            />

            <div class="mt-2 text-xs">{{ $t('help_clearing_systems') }}</div>
        </AccordionBasic>
        <AccordionBasic
            title="order_status_for_total_funding_sum"
            class="mb-2"
        >
            <h3 class="mb-1 block text-sm font-medium">
                {{ $t('order_status_for_total_funding_sum') }}
                <span class="text-danger-500">*</span>
            </h3>
            <ToggleSwitch
                v-model="orderStatusPending"
                name="pending"
                label="pending"
            />
            <ToggleSwitch
                v-model="orderStatusPaid"
                name="paid"
                label="paid"
            />
            <ToggleSwitch
                v-model="orderStatusAccepted"
                name="accepted"
                label="accepted"
            />

            <div class="mt-2 text-xs">
                {{ $t('help_order_status_for_total_funding_sum') }}
            </div>
        </AccordionBasic>
        <AccordionBasic
            title="smtp_settings"
            class="mb-2"
        >
            <TextField
                v-model="platform.mail_host"
                name="mail_host"
                label="mail_host"
                help="help_mail_host"
                :required="true"
            />
            <NumberField
                v-model="platform.mail_port"
                name="mail_port"
                label="mail_port"
                help="help_mail_port"
                :required="true"
            />
            <SelectField
                v-model="platform.mail_encryption"
                name="mail_encryption"
                label="mail_encryption"
                help="help_mail_encryption"
                :options="[
                    {
                        label: $t('none'),
                        value: null,
                    },
                    {
                        label: 'TLS',
                        value: 'tls',
                    },
                    {
                        label: 'SSL',
                        value: 'ssl',
                    },
                ]"
            />
            <TextField
                v-model="platform.mail_from_address"
                name="mail_from_address"
                label="mail_from_address"
                help="help_mail_from_address"
                :required="true"
            />
            <TextField
                v-model="platform.mail_from_name"
                name="mail_form_name"
                label="mail_form_name"
                help="help_mail_form_name"
                :required="true"
            />
            <TextField
                v-model="platform.mail_username"
                name="mail_username"
                label="mail_username"
                help="help_mail_username"
            />
            <TextField
                v-model="platform.mail_password"
                name="mail_password"
                label="mail_password"
                help="help_mail_password"
            />
        </AccordionBasic>
        <AccordionBasic
            v-if="platform.has_credential_voucherify"
            title="voucherify_settings"
            class="mb-2"
        >
            <TextField
                v-model="platform.voucher_wallet"
                name="voucher_wallet"
                label="voucher_wallet"
                help="help_voucher_wallet"
            />
            <h3 class="mb-2 font-bold">{{ $t('voucher_campaigns') }}</h3>
            <div
                v-for="(campaign, index) in platform.voucherify_campaigns"
                :key="index"
                class="flex flex-row gap-4"
            >
                <TextField
                    v-model="campaign.voucherify_campaign_name"
                    name="voucher_campaign_name"
                    label="voucher_campaign_name"
                    help="help_voucher_campaign_name"
                    :disabled="campaign.created_at !== null"
                />
                <SelectField
                    v-model="campaign.type"
                    name="voucher_campaign_type"
                    label="voucher_campaign_type"
                    help="help_voucher_campaign_type"
                    :options="mapEnumToArray(VoucherCampaignType)"
                    :disabled="true"
                />
                <div class="my-4">
                    <ToggleSwitch
                        v-model="campaign.default"
                        class="mt-8"
                        :name="'voucher_campaign_default-' + index"
                        label="voucher_campaign_default"
                        help="help_voucher_campaign_default"
                        @toggle="() => toggleVoucherCampaignDefault(index)"
                    />
                </div>
                <div class="mt-10">
                    <DeleteButton
                        v-if="!campaign.default"
                        @click="deleteVoucherifyCampaign(campaign)"
                    />
                </div>
            </div>
            <button
                class="btn-sm bg-primary-additional-500 hover:bg-primary-additional-600 text-white"
                @click.stop="addVoucherCampaign"
            >
                {{ $t('add_voucher_campaign') }}
            </button>
        </AccordionBasic>
        <AccordionBasic
            title="active_emails"
            class="mb-2"
        >
            <div
                v-for="template_name in PlatformEmailTemplates"
                :key="template_name"
                class="border-b border-gray-200 py-4 last:border-0"
            >
                <ToggleSwitch
                    v-model="platform[template_name]"
                    :name="template_name"
                    :label="template_name"
                    :help="`help_${template_name}`"
                >
                </ToggleSwitch>
                <div
                    v-if="
                        schedulePropertyExists(template_name, platform) &&
                        platform[template_name] === true &&
                        showDurationPicker
                    "
                    class="intervals mt-1 inline-block rounded border-2 p-2 py-2 shadow"
                >
                    <div
                        v-for="(val, index) in platform[
                            convertToScheduleProperty(template_name)
                        ]"
                        :key="index"
                    >
                        <DurationPicker
                            v-model="
                                platform[
                                    convertToScheduleProperty(template_name)
                                ][index]
                            "
                            class="inline-block"
                        />
                        <DeleteButton
                            class="ml-1"
                            @click="
                                deleteScheduleInterval(
                                    index,
                                    convertToScheduleProperty(template_name),
                                    platform
                                )
                            "
                        />
                    </div>

                    <button
                        class="btn-sm bg-primary-additional-500 hover:bg-primary-additional-600 mt-2 w-full p-2 text-white"
                        @click="
                            addNewValueForScheduleProperty(
                                platform,
                                convertToScheduleProperty(template_name)
                            )
                        "
                    >
                        <fa icon="plus" />
                        <span class="ml-2">{{ $t('add') }}</span>
                    </button>
                </div>
            </div>
        </AccordionBasic>
        <AccordionBasic
            title="qr_code_type"
            class="mb-2"
        >
            <SelectField
                v-model="platform.payment_qr_code_type"
                name="payment_qr_code_type"
                label="payment_qr_code_type"
                help="help_payment_qr_code_type"
                :options="mapEnumToArray(QRCodeType)"
                title="payment_qr_code_type"
                class="mb-2"
                :required="true"
            />
        </AccordionBasic>
        <AccordionBasic
            title="platform_compliance_profile"
            class="mb-2"
        >
            <div>
                <SelectField
                    v-model="platform.compliance_profile"
                    :options="mapEnumToArray(PlatformComplianceProfile)"
                    name="platform_compliance_profile"
                    label="platform_compliance_profile"
                    help="help_platform_compliance_profile"
                />
            </div>
            <div class="mt-4">
                <FileUploadButton
                    v-if="platform.compliance_profile"
                    title="self_disclosure_form"
                    accept=".pdf"
                    :file-name="platform?.self_disclosure_form?.name"
                    @submit="onSelfDisclosureFormUpload"
                    @delete="onSelfDisclosureFormDelete"
                />
            </div>
        </AccordionBasic>
        <AccordionBasic
            v-if="userStore.can('set_kyc_provider')"
            title="kyc_provider"
            class="mb-2"
        >
            <SelectField
                v-model="platform.kyc_provider"
                name="kyc_provider"
                label="kyc_provider"
                help="help_kyc_provider"
                :options="mapEnumToArray(KycProvider)"
                title="kyc_provider"
                class="mb-2"
            />
        </AccordionBasic>
        <AccordionBasic
            v-if="platform.esac_kyc_active"
            title="esac_kyc"
            class="mb-2"
        >
            <div>
                <FileUploadButton
                    title="esac_form_natural_person"
                    accept=".pdf"
                    :file-name="platform.esac_form_natural_person.name"
                    :url="platform.esac_form_natural_person.thumb_image_url"
                    @submit="onEsacFormNaturalPersonUpload"
                    @delete="onEsacFormNaturalPersonDelete"
                />
            </div>
            <div class="mt-4">
                <FileUploadButton
                    title="esac_form_legal_entity"
                    accept=".pdf"
                    :file-name="platform.esac_form_legal_entity.name"
                    :url="platform.esac_form_legal_entity.thumb_image_url"
                    @submit="onEsacFormLegalEntityUpload"
                    @delete="onEsacFormLegalEntityDelete"
                />
            </div>
            <div class="mt-4">
                <FileUploadButton
                    title="esac_form_legal_ubo"
                    accept=".pdf"
                    :file-name="platform.esac_form_legal_ubo.name"
                    :url="platform.esac_form_legal_ubo.thumb_image_url"
                    @submit="onEsacFormLegalUboUpload"
                    @delete="onEsacFormLegalUboDelete"
                />
            </div>
        </AccordionBasic>
        <AccordionBasic
            v-if="platform.is_platform"
            title="acceptance_document"
            help="help_acceptance_document"
        >
            <ToggleSwitch
                v-model="platform.acceptance_document_active"
                name="acceptance_document_active"
                label="acceptance_document_active"
                help="help_acceptance_document_active"
            />

            <div class="mt-4">
                <FileUploadButton
                    v-if="platform.acceptance_document_active"
                    title="acceptance_document"
                    accept=".pdf"
                    :file-name="platform?.acceptance_document?.name"
                    :url="platform?.acceptance_document.url"
                    @submit="onAcceptanceDocumentUpload"
                    @delete="onAcceptanceDocumentDelete"
                />
            </div>
        </AccordionBasic>
        <AccordionBasic title="membership_management">
            <ToggleSwitch
                v-model="platform.membership_management_active"
                name="membership_management_active"
                label="membership_management_active"
                help="help_membership_management_active"
            />

            <div class="mt-4">
                <FileUploadButton
                    :disabled="!platform.membership_management_active"
                    title="upload_document"
                    accept=".pdf"
                    :file-name="platform?.membership_document?.name"
                    :url="platform?.membership_document?.url"
                    @submit="onMembershipDocumentUpload"
                    @delete="onMembershipDocumentDelete"
                />
            </div>

            <ToggleSwitch
                v-model="platform.membership_type_manual"
                name="membership_type_manual"
                label="membership_type_manual"
                :disabled="!platform.membership_management_active"
                class="mt-10"
            />
            <ToggleSwitch
                v-model="platform.membership_type_sproof"
                name="membership_type_sproof"
                label="membership_type_sproof"
                :disabled="true"
            />

            <ToggleSwitch
                v-if="platform.membership_type_sproof"
                v-model="platform.qualified_signature"
                name="qualified_signature"
                label="qualified_signature"
                help="help_qualified_signature"
                :disabled="!platform.membership_management_active"
            />

            <ToggleSwitch
                v-model="platform.use_rule"
                name="use_rule"
                label="use_rule"
                help="help_use_rule"
                :disabled="!platform.membership_management_active"
                class="mt-10"
            />

            <ToggleSwitch
                v-model="platform.membership_number_active"
                name="membership_number_active"
                label="membership_number_active"
                help="help_membership_number_active"
                :disabled="!platform.membership_management_active"
            />

            <TextField
                v-if="platform.membership_number_active"
                v-model="platform.membership_number_prefix_natural"
                name="membership_number_prefix_natural"
                label="membership_number_prefix_natural"
                help="help_membership_number_prefix_natural"
            />
            <TextField
                v-if="platform.membership_number_active"
                v-model="platform.membership_number_prefix_legal"
                name="membership_number_prefix_legal"
                label="membership_number_prefix_legal"
                help="help_membership_number_prefix_legal"
            />
            <TextField
                v-model="platform.membership_document_description"
                :disabled="!platform.membership_management_active"
                name="membership_document_description"
                :rows="3"
                label="membership_document_description"
                help="help_membership_document_description"
            />
        </AccordionBasic>
    </PageWrapper>
</template>
<script setup lang="ts">
import { useEnum } from '@composables/common'
import BackButton from '@partials/actions/BackButton.vue'
import FileUploadButton from '@partials/actions/FileUploadButton.vue'
import {
    AccordionBasic,
    ColorPickerField,
    ImageUpload,
    NumberField,
    SelectField,
    TextField,
    ToggleSwitch,
} from '@src/components'
import { QRCodeType, VoucherCampaignType } from '@src/enums'
import {
    KycProvider,
    PlatformComplianceProfile,
    PlatformEmailTemplates,
} from '@src/enums/Platform'
import SaveButton from '@src/partials/actions/SaveButton.vue'
import PageWrapper from '@src/partials/pages/PageWrapper.vue'
import { Platform, VoucherCampaign } from '@src/types'
import {
    getPlatform,
    getPlatforms,
    updatePlatform,
} from '@src/utils/api/platforms'
import { UploadFileResponse } from '@src/utils/api/s3'
import { useToastStore } from '@stores/toast'
import { Ref, computed, onMounted, ref, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@src/utils/stores/user'
import DeleteButton from '@partials/actions/DeleteButton.vue'
import DurationPicker from '@components/DurationPicker.vue'

const { mapEnumToArray } = useEnum()

const router = useRouter()
const route = useRoute()
const toast = useToastStore()
const i18n = useI18n()
const userStore = useUserStore()
const showDurationPicker = ref(true)

async function savePlatform() {
    platform.value.funding_total_status = orderStatusSelection.value
    clearAndFormatMailSchedules(platform.value)

    if (
        platform.value.membership_management_active === true &&
        platform.value.membership_document.id === null
    ) {
        toast.error(
            i18n.t('toast_error_platform_saving_membership_document_missing', {
                platform: platform.value.platform_name,
            })
        )
    } else {
        await updatePlatform(platform.value)
        toast.success(
            i18n.t('toast_success_platform_saved', {
                platform: platform.value.platform_name,
            })
        )
        if (platform.value.platform_logo) {
            platform.value.platform_logo.upload_key = null
        }
        loadPlatform()
    }
}

function goToDetails(): void {
    router.push({
        name: 'platform',
        params: { slug: platform.value.platform_name },
    })
}

function toPlatforms() {
    router.push({ name: 'platforms' })
}

const platform = ref<Platform>(new Platform())
const parentPlatformOptions = ref<string[]>([])

async function loadParentPlatformOptions(search: string) {
    const initialPlatformsRequest = await getPlatforms({
        'filter[platform_name]': search,
        'filter[is_not_child]': true,
    })
    parentPlatformOptions.value = initialPlatformsRequest.data.platforms.map(
        (platform: Platform) => {
            return platform.platform_name as string
        }
    )
}

// clearing systems
// FIXME: this is a bit hacky, but it works for now. We should probably refactor this to use the computed() function as with the orderStatusSelection
function handleCS(cs: string, value: boolean): void {
    if (value === true) {
        if (
            !platform.value.clearing_systems.find(
                (item) => item.clearing_system === cs
            )
        ) {
            platform.value.clearing_systems.push({ clearing_system: cs })
        }
    }
    if (value === false) {
        const clearingSystem = platform.value.clearing_systems.find(
            (item) => item.clearing_system === cs
        )
        if (clearingSystem) {
            platform.value.clearing_systems.splice(
                platform.value.clearing_systems.indexOf(clearingSystem),
                1
            )
        }
    }
}
const clearingSystemCi: Ref<boolean> = ref<boolean>(false)
watch(clearingSystemCi, (value) => handleCS('CI', value))
const clearingSystemCf: Ref<boolean> = ref<boolean>(false)
watch(clearingSystemCf, (value) => handleCS('CF', value))
const clearingSystemEmission: Ref<boolean> = ref<boolean>(false)
watch(clearingSystemEmission, (value) => handleCS('EMISSION', value))

// order status for funding sum
const orderStatusPending: Ref<boolean> = ref<boolean>(false)
const orderStatusPaid: Ref<boolean> = ref<boolean>(false)
const orderStatusAccepted: Ref<boolean> = ref<boolean>(false)
const orderStatusSelection = computed((): number[] => {
    const orderStatus = []
    if (orderStatusPending.value) {
        orderStatus.push(0)
    }
    if (orderStatusPaid.value) {
        orderStatus.push(1)
    }
    if (orderStatusAccepted.value) {
        orderStatus.push(2)
    }
    return orderStatus
})

function addVoucherCampaign() {
    const voucherCampaign = new VoucherCampaign()
    if (!platform.value.voucherify_campaigns.length) {
        voucherCampaign.default = true
    }
    platform.value.voucherify_campaigns.push(voucherCampaign)
}

function toggleVoucherCampaignDefault(index: number) {
    platform.value.voucherify_campaigns.forEach((campaign, i) => {
        if (i === index) {
            campaign.default = true
        } else {
            campaign.default = false
        }
    })
}

async function loadPlatform() {
    const platformRequest = await getPlatform(route.params.slug as string)
    platform.value = platformRequest.data.platform

    if (platform.value.parent_platform_name) {
        await loadParentPlatformOptions(platform.value.parent_platform_name)
    }
}

function onEsacFormNaturalPersonUpload(
    response: UploadFileResponse,
    fileName: string
) {
    platform.value.esac_form_natural_person.name = fileName
    platform.value.esac_form_natural_person.upload_key = response.key
    platform.value.esac_form_natural_person.thumb_image_url =
        response.temp_storage_url
}

function onEsacFormNaturalPersonDelete() {
    platform.value.esac_form_natural_person = {
        delete: true,
    }
}

function onEsacFormLegalEntityUpload(
    response: UploadFileResponse,
    fileName: string
) {
    platform.value.esac_form_legal_entity.name = fileName
    platform.value.esac_form_legal_entity.upload_key = response.key
    platform.value.esac_form_legal_entity.thumb_image_url =
        response.temp_storage_url
}

function onEsacFormLegalEntityDelete() {
    platform.value.esac_form_legal_entity = {
        delete: true,
    }
}

function onEsacFormLegalUboUpload(
    response: UploadFileResponse,
    fileName: string
) {
    platform.value.esac_form_legal_ubo.name = fileName
    platform.value.esac_form_legal_ubo.upload_key = response.key
    platform.value.esac_form_legal_ubo.thumb_image_url =
        response.temp_storage_url
}

function onEsacFormLegalUboDelete() {
    platform.value.esac_form_legal_ubo = {
        delete: true,
    }
}

function onAcceptanceDocumentUpload(
    response: UploadFileResponse,
    fileName: string
) {
    platform.value.acceptance_document = {
        name: fileName,
        upload_key: response.key,
        thumb_image_url: response.temp_storage_url,
    }
}

function onAcceptanceDocumentDelete() {
    platform.value.acceptance_document = {
        delete: true,
    }
}

function onMembershipDocumentUpload(
    response: UploadFileResponse,
    fileName: string
) {
    platform.value.membership_document = {
        name: fileName,
        upload_key: response.key,
        thumb_image_url: response.temp_storage_url,
    }
}

function onMembershipDocumentDelete() {
    platform.value.membership_document = {
        delete: true,
    }
}

function onSelfDisclosureFormUpload(
    response: UploadFileResponse,
    fileName: string
) {
    platform.value.self_disclosure_form = {
        name: fileName,
        upload_key: response.key,
        thumb_image_url: response.temp_storage_url,
    }
}

function onSelfDisclosureFormDelete() {
    platform.value.self_disclosure_form = {
        delete: true,
    }
}
function deleteScheduleInterval(
    indexToDelete: number,
    prop: string,
    platform: Platform
) {
    showDurationPicker.value = false
    platform[prop] = platform[prop].filter(
        (item: number, index: number) => indexToDelete !== index
    )
    nextTick().then(() => {
        showDurationPicker.value = true
    })
}

function clearAndFormatMailSchedules(platform: Platform) {
    PlatformEmailTemplates.forEach((item) => {
        const scheduled_prop = convertToScheduleProperty(item)
        if (schedulePropertyExists(item, platform)) {
            if (platform[scheduled_prop] === null) {
                platform[scheduled_prop] = []
            }
            platform[scheduled_prop] = platform[scheduled_prop].filter(
                (item) => item > 0
            )
            //platform[scheduled_prop] = JSON.stringify(platform[scheduled_prop])
        }
    })
}

function addNewValueForScheduleProperty(
    platform: Platform,
    prop: string
): void {
    if (platform[prop] === null) {
        platform[prop] = []
    }
    platform[prop].push(0)
}

function convertToScheduleProperty(property: string): string {
    return property.replace('mail_active_', 'mail_schedule_')
}

function schedulePropertyExists(template: string, platform: object): boolean {
    const schedule_prop = convertToScheduleProperty(template)
    return Object.keys(platform).includes(schedule_prop)
}

function deleteVoucherifyCampaign(voucherifyCampaign: VoucherCampaign) {
    const index = platform.value.voucherify_campaigns.indexOf(
        voucherifyCampaign,
        0
    )
    if (index > -1) {
        platform.value.voucherify_campaigns.splice(index, 1)
    }
}

onMounted(async () => {
    if (route.params.slug) {
        await loadPlatform()
        clearingSystemCi.value = platform.value.clearing_systems.find(
            (cs) => cs.clearing_system === 'CI'
        )
            ? true
            : false
        clearingSystemCf.value = platform.value.clearing_systems.find(
            (cs) => cs.clearing_system === 'CF'
        )
            ? true
            : false
        clearingSystemEmission.value = platform.value.clearing_systems.find(
            (cs) => cs.clearing_system === 'EMISSION'
        )
            ? true
            : false
        orderStatusPending.value =
            platform.value.funding_total_status.includes(0)
        orderStatusPaid.value = platform.value.funding_total_status.includes(1)
        orderStatusAccepted.value =
            platform.value.funding_total_status.includes(2)
    }
})
</script>
